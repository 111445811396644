import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import get from 'lodash.get';

import { getGradeFromLocalStorage } from '../../helpers/gradeHelpers';
import { isValidGradeValue } from '../../helpers/validationHelper';
import { gradeTypes } from '../../constants';

import { GradeLevelDropdown } from './GradeLevelDropdown/GradeLevelDropdown';
// import SubjectIcon from 'mdi-react/GamepadCircleRightIcon';
// import SubjectIcon from 'mdi-react/FormatListBulletedIcon';
import ArrowDownIcon from 'mdi-react/UnfoldMoreHorizontalIcon';

import * as gradeActions from '../../actions/gradeActions';

import styles from './gradeInputLine.module.scss';

class GradeInputLineComponent extends Component {
    componentDidMount() {
        const { subject, setGradeValue, setGradeLevel } = this.props;
        const { dalykas_id: subjectId } = subject;
        const { dalykas_settings: settings } = subject;
        const dataFromLocalStorage = getGradeFromLocalStorage(subjectId);

        if (settings[gradeTypes.TEST]) {
            const tValue = get(dataFromLocalStorage, `['${gradeTypes.TEST}'].value`) || '';
            setGradeValue({
                subjectId,
                gradeType: gradeTypes.TEST,
                value: tValue,
                skipLocalStorage: true
            });
        }

        if (settings[gradeTypes.EXAM]) {

            const isValidExam = get(dataFromLocalStorage, `['${gradeTypes.EXAM}'].isValid`, true);
            const eValue = get(dataFromLocalStorage, `['${gradeTypes.EXAM}'].value`) || '';
            const eLevel = this.getLevel({
                lsLevel: get(dataFromLocalStorage, `[${gradeTypes.EXAM}].level`),
                type: gradeTypes.EXAM,
                settings,
            });

            setGradeValue({
                subjectId,
                gradeType: gradeTypes.EXAM,
                value: eValue,
                isValid: isValidExam,
                skipLocalStorage: true
            });

            setGradeLevel({
                subjectId,
                gradeType: gradeTypes.EXAM,
                level: eLevel,
                skipLocalStorage: true
            });
        }

        if (settings[gradeTypes.ANNUAL]) {
            const isValidAnnual = get(dataFromLocalStorage, `['${gradeTypes.ANNUAL}'].isValid`, true);
            const aValue = get(dataFromLocalStorage, `['${gradeTypes.ANNUAL}'].value`) || '';
            const aLevel = this.getLevel({
                lsLevel: get(dataFromLocalStorage, `[${gradeTypes.ANNUAL}].level`),
                type: gradeTypes.ANNUAL,
                settings,
            });

            setGradeValue({
                subjectId,
                gradeType: gradeTypes.ANNUAL,
                value: aValue,
                isValid: isValidAnnual,
                skipLocalStorage: true
            });

            setGradeLevel({
                subjectId,
                gradeType: gradeTypes.ANNUAL,
                level: aLevel,
                skipLocalStorage: true
            });
        }
    }

    getLevel = ({ settings, type, lsLevel }) => {
        const availableLevels = get(settings, `['${type}'].levels`) || [];
        return availableLevels.includes(lsLevel) ? lsLevel : availableLevels[0];
    };

    onInput = (gradeType, e) => {
        let value = get(e, 'target.value') || 0;
        const { subject } = this.props;
        const isValid = this.validate(gradeType, e);

        this.props.setGradeValue({
            subjectId: subject.dalykas_id,
            gradeType,
            value,
            isValid
        });
    };

    onLevelChange = (gradeType, option) => {
        const { subject } = this.props;

        this.props.setGradeLevel({
            subjectId: subject.dalykas_id,
            gradeType,
            level: option
        });
    };

    validate = (gradeType, e) => {
        const value = get(e, 'target.value');
        const { subject, gradeState } = this.props;
        const { dalykas_id: subjectId } = subject;
        const subjectGrades = get(gradeState, `grades[${subjectId}]`) || {};
        const level = get(subjectGrades, `${gradeType}.level`) || '';

        if (!value) {
            return true;
        }

        const result = isValidGradeValue({
            subjectId,
            gradeType,
            value,
            level,
        });

        return !!result;
    };

    render() {
        const { subject, gradeState, uiState } = this.props;
        const pathParams = get(this.props, 'dataState.pathParams');
        const { dalykas_settings: settings, dalykas_id: subjectId } = subject;
        const subjectGrades = get(gradeState, `grades[${subjectId}]`) || {};
        const testValue = get(subjectGrades, `${gradeTypes.TEST}.value`) || '';
        const examValue = get(subjectGrades, `${gradeTypes.EXAM}.value`) || '';
        const examLevel = get(subjectGrades, `${gradeTypes.EXAM}.level`) || '';
        const annualValue = get(subjectGrades, `${gradeTypes.ANNUAL}.value`) || '';
        const annualLevel = get(subjectGrades, `${gradeTypes.ANNUAL}.level`) || '';

        const isValidExam = get(subjectGrades, `${gradeTypes.EXAM}.isValid`, true);
        const isValidAnnual = get(subjectGrades, `${gradeTypes.ANNUAL}.isValid`, true);

        const selectedSubjectIds = get(uiState, 'selectedSubjectIds') || [];
        const subjectIsUsed = selectedSubjectIds.includes(subjectId);

        const testOptions = get(settings, 'test.options') || [];
        const testActiveValueLabel = testOptions.find(item => item.value === testValue) || {};

        const wrapperClassName = classnames(styles.wrapper, {
            [styles.isUsed]: subjectIsUsed && pathParams.programId,
        });

        if (settings.hidden) {
            return null;
        }

        return (
            <li className={wrapperClassName}>
                <span className={styles.title}>
                    {/* <SubjectIcon size={30} className={styles.icon} /> */}
                    {subject.dalykas_name}
                </span>

                {settings.test && <span className={styles.test}>
                    <select
                        onChange={e => this.onInput(gradeTypes.TEST, e)}
                        value={testValue}
                    >
                        {settings.test.options.map(option => {
                            const { label, value } = option;
                            return (<option key={value} value={value}>{label}</option>)
                        })}
                    </select>
                    <span className={styles.dropDownVisibleValue}>
                        {testActiveValueLabel.label || '- pasirinkite -'}
                        <ArrowDownIcon />
                    </span>
                </span>}

                {settings.exam && <span className={styles.exam}>
                    <input type="number"
                           inputMode="numeric"
                           max="100"
                           name={`examInput_${subjectId}`}
                           className={!isValidExam ? styles.invalidInput : undefined}
                           onChange={e => this.onInput(gradeTypes.EXAM, e)}
                           value={examValue}
                    />
                    {!settings.exam.levelsHidden && <GradeLevelDropdown
                        options={settings.exam.levels}
                        selected={examLevel}
                        onLevelChange={value => this.onLevelChange(gradeTypes.EXAM, value)}
                    />}
                </span>}
                {!settings.exam && !settings.test && <span className={styles.exam} />}

                {settings.annual && !settings.test && <span className={styles.annual}>
                    <input type="number"
                           inputMode="numeric"
                           max="100"
                           name={`annualInput_${subjectId}`}
                           className={!isValidAnnual ? styles.invalidInput : undefined}
                           onChange={e => this.onInput(gradeTypes.ANNUAL, e)}
                           onInput={e => this.validate(gradeTypes.ANNUAL, e)}
                           value={annualValue}
                    />
                    {!settings.annual.levelsHidden && <GradeLevelDropdown
                        options={settings.annual.levels}
                        selected={annualLevel}
                        onLevelChange={value => this.onLevelChange(gradeTypes.ANNUAL, value)}
                    />}
                </span>}
                {!settings.annual && !settings.test && <span className={styles.annual} />}

            </li>
        );
    }
}

const mapStateToProps = state => ({
    gradeState: state.gradeState,
    dataState: state.dataState,
    uiState: state.uiState
});

const mapDispatchToProps = { ...gradeActions };

export const GradeInputLine = connect(mapStateToProps, mapDispatchToProps)(GradeInputLineComponent);
