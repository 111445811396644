import React, { Component } from 'react';
import { connect } from 'react-redux';
import device from 'current-device';

import { GradeInputLine } from './GradeInputLine';
import { GeneralRequirement } from '../../calculator/generalRequirement';
import { WarningMessage } from '../WarningMessage/WarningMessage';
import * as gradeActions from '../../actions/gradeActions';
import * as dataActions from '../../actions/dataActions';

import stylesInputLine from './gradeInputLine.module.scss'
import styles from './gradeInput.module.scss';

const errorListsMatch = (arr1, arr2) => {
    const compareFn = (item1, item2) => {
        return (item1.code === item2.code) && (item1.compareString === item2.compareString)
    };

    return arr1.every(item1 => arr2.find(item2 => compareFn(item1, item2)));
};

// export @connect(state => ({
//     gradeState: state.gradeState,
//     dataState: state.dataState,
// }), { ...gradeActions, ...dataActions })
class GradeInputComponent extends Component {
    componentDidUpdate(previousProps, previousState, previousContext) {
        if (this.props.dataState.initialDataFetched) {
            this.checkBaseRequirements();
        }

    }

    checkBaseRequirements = () => {
        const { gradeState, dataState, setMandatoryRequirementsErrors } = this.props;
        const { dalykas: subjects, text } = dataState;
        let shouldUpdateStore = false;

        const generalRequirements = new GeneralRequirement({
            text,
            rules: { extra: {} },
            isUniversity: false,
            gradeState: gradeState,
            subjects
        });

        const mandatoryRequirementsErrors = generalRequirements.testMandatory();

        if (mandatoryRequirementsErrors.length !== dataState.mandatoryRequirementsErrors.length) {
            shouldUpdateStore = true;
        } else if (mandatoryRequirementsErrors.length) {
            shouldUpdateStore = !errorListsMatch(mandatoryRequirementsErrors, dataState.mandatoryRequirementsErrors);
        }

        if (shouldUpdateStore) {
            setMandatoryRequirementsErrors({ mandatoryRequirementsErrors });
            // console.log({ mandatoryRequirementsErrors });
        }
    };

    render() {
        const { subjects, stickyMode, dataState } = this.props;
        const isMobile = device.mobile();
        const { mandatoryRequirementsErrors } = dataState;
        const subjectsArray = Object.values(subjects || {});
        const orderedSubjects = subjectsArray.sort((a,b) => {
            return (a.dalykas_order > b.dalykas_order) ? 1 : ((b.dalykas_order > a.dalykas_order) ? -1 : 0)
        });
        const hasErrorsClass = mandatoryRequirementsErrors.length ? styles.hasErrors : null;

        return (
            <form id="kbs-grade-form" className={`${styles.wrapper} ${stickyMode ? styles.stickyMode : ''} ${hasErrorsClass}`}>
                <ul className={styles.ul}>
                    <li className={`${stylesInputLine.wrapper} ${styles.header}`}>
                        <span className={stylesInputLine.title}>Dalykas</span>
                        <span className={stylesInputLine.exam}>Egzaminas</span>
                        <span className={stylesInputLine.annual}>Metinis</span>
                    </li>
                    {orderedSubjects.map(subject => {
                        return <GradeInputLine key={subject.dalykas_id} subject={subject} />;
                    })}
                </ul>


                {isMobile && <WarningMessage errors={mandatoryRequirementsErrors} />}
            </form>
        );
    }
}

const mapStateToProps = state => ({
    gradeState: state.gradeState,
    dataState: state.dataState,
});

const mapDispatchToProps = { ...gradeActions, ...dataActions };

export const GradeInput = connect(mapStateToProps, mapDispatchToProps)(GradeInputComponent);
