import React, { Component } from 'react';
import { HashRouter as Router, Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import { Provider, connect } from 'react-redux';
import device from 'current-device';
import get from 'lodash.get';
import { parse } from 'query-string';
import { trackPageView } from '../../helpers/trackingHelper';

import { ResultBox } from '../ResultBox/ResultBox';
import { Home } from '../Home/Home';
import { ScienceFields } from '../ScienceFields/ScienceFields';
import { GradeInput } from '../GradeInput/GradeInput';
import { SchoolFilter } from '../SchoolFilter/SchoolFilter';
import { TopToolbar } from '../TopToolbar/TopToolbar';
import { IntroMobileBlock } from '../IntroMobileBlock/IntroMobileBlock';

import store from '../../store';
import * as dataActions from '../../actions/dataActions';
import * as uiActions from '../../actions/uiActions';
import { isEqual } from '../../helpers/objectCompareHelper';
import { parsePathParams } from '../../helpers/routerHelper';

import styles from './layout.module.scss';


window.addEventListener('beforeinstallprompt', e => {
    e.preventDefault();
    window.deferredAHSPrompt = e;
});

class LayoutComponent extends Component {
    state = {
        path: null,
        queryParams: {},
        params: {},
        stickyMode: false,
    };

    constructor(props) {
        super(props);
        this.handleRouteChange = this.handleRouteChange.bind(this);
    }

    componentDidMount() {
        this.props.setIsMobile(device.mobile());
        this.props.setHistory(this.props.history);
        this.props.fetchInitialData();
        if (!device.mobile()) {
            window.addEventListener('scroll', this.onScroll);
        }
    }

    componentWillUnmount() {
        if (!device.mobile()) {
            window.removeEventListener('scroll', this.onScroll);
            // window.removeEventListener('mousewheel', this.onScroll);
        }
    }

    componentDidUpdate(previousProps) {
        const initialDataWasFetched = get(previousProps, 'dataState.initialDataFetched');
        const initialDataIsFetched = get(this, 'props.dataState.initialDataFetched');
        const initialDataIsFetchedChanged = !initialDataWasFetched && initialDataIsFetched;

        const { dataState } = this.props;
        const { pathname: path, search } = this.props.location;
        const queryParams = parse(search);


        const isEqualQueryParams = isEqual(dataState.queryParams, queryParams);
        const isEqualPath = dataState.path === path;
        if (!isEqualQueryParams || !isEqualPath || initialDataIsFetchedChanged) {
            this.handleRouteChange({
                path,
                queryParams,
                search,
            });
        }
    }

    handleRouteChange({ path, queryParams, search }) {
        const { filterPrograms } = this.props;
        const { schools = '' } = queryParams;

        const schoolsIds = schools
            .split(',')
            .filter(Boolean)
            .map(item => +item);
        const pathParams = parsePathParams(path);
        const { branchId, programId, searchString } = pathParams;

        this.props.setSchoolSelection(schoolsIds);
        this.props.setPath(path);
        this.props.setQueryParams(queryParams);

        filterPrograms({
            branchId,
            programId,
            schoolsIds,
            searchString,
        });

        return;
    }

    trackPageView = ({ path, search, params }) => {
        // if (queryParams.pid) {
        //     // tracking will fire in ResultBox component
        //     return;
        // }
        trackPageView({ url: `${path}${search}` });
    };

    setWrapper = el => {
        if (!el) {
            return;
        }

        this.wrapper = el;
        uiActions.setWrapper(el);
        this.rightAdsBlock = document.querySelector('#right-ads-wrap');

        if (this.rightAdsBlock) {
            this.pageContentBlock = document.querySelector('#content-wrap');
            const pageContentBlockTop = this.pageContentBlock.getBoundingClientRect().top;
            const wrapperTop = this.wrapper.getBoundingClientRect().top;
            this.rightAdsBlockStickyTop = wrapperTop - pageContentBlockTop + 50;
            this.rightAdsBlock.style.transition = 'all 2000ms easy-in';
            this.rightAdsBlock.style.position = 'sticky';
            this.rightAdsBlock.style.top = '10px';

            this.pageContentBlock.style.minHeight = `${this.rightAdsBlock.offsetHeight + 800}px`;

            // hide left block
            document.querySelector('#content-left-wrap').style.display = 'none';
        }
    };

    onScroll = e => {
        const { stickyMode } = this.state;

        if (!this.wrapper) {
            return;
        }

        const viewportOffset = this.wrapper.getBoundingClientRect();
        const toolbarShouldStick = viewportOffset.top <= -20;

        if (toolbarShouldStick && !stickyMode) {
            this.setState({ stickyMode: true });
        } else if (!toolbarShouldStick && stickyMode) {
            this.setState({ stickyMode: false });
        }

        this.handleRightSideAds();
    };

    handleRightSideAds = () => {
        const { stickyMode } = this.state;

        if (!this.rightAdsBlock || !this.pageContentBlock || !device.desktop()) {
            return;
        }

        if (stickyMode) {
            this.rightAdsBlock.style.position = 'relative';
            this.rightAdsBlock.style.top = this.rightAdsBlockStickyTop;
        } else {
            this.rightAdsBlock.style.position = 'sticky';
        }
    };

    render() {
        const { dataState } = this.props;
        const { stickyMode } = this.state;
        const { queryParams = {}, pathParams = {} } = dataState;
        const isMobile = device.mobile();
        const initialDataFetched = get(dataState, 'initialDataFetched');
        const introText = get(dataState, 'text[1]');
        const titleText = get(dataState, 'text[3]');
        const mandatoryRequirementsErrors = get(dataState, 'mandatoryRequirementsErrors');

        const desktopIntroBox = !isMobile && (
            <div className={styles.desktopIntroWrapper}>
                <h1 dangerouslySetInnerHTML={{ __html: titleText }} />
                <div dangerouslySetInnerHTML={{ __html: introText }} />
            </div>
        );

        if (!initialDataFetched) {
            return (
                <div className={`${styles.wrapper} ${isMobile && styles.mobileWrapper} kbsWrapper`}>
                    <div className={styles.loading}>Palaukite...</div>
                </div>
            );
        }

        const DefaultComponent = isMobile ? IntroMobileBlock : Home;

        return (
            <div className={`${styles.wrapper} ${isMobile && styles.mobileWrapper} kbsWrapper`}>
                {desktopIntroBox}

                <div className={styles.topToolbarHolder} ref={el => this.setWrapper(el)}>
                    <TopToolbar
                        stickyMode={stickyMode}
                        isMobile={isMobile}
                        queryParams={queryParams}
                        pathParams={pathParams}
                        currentHref={dataState.path}
                        mandatoryRequirementsErrors={mandatoryRequirementsErrors}
                    />
                </div>

                <div className={styles.verticalSplit}>
                    <div className={styles.leftBlock} hidden={isMobile && !queryParams.grades}>
                        <GradeInput subjects={dataState.dalykas} stickyMode={stickyMode} />
                    </div>

                    <div className={styles.rightBlock} hidden={!!queryParams.grades}>
                        <Route exact path="/" queryParams={queryParams}>
                            <DefaultComponent
                                titleText={titleText}
                                introText={introText}
                                mandatoryRequirementsErrors={mandatoryRequirementsErrors}
                            />
                        </Route>

                        <Route exact path="/home" queryParams={queryParams}>
                            <Home isMobile={isMobile} />
                        </Route>

                        <Route
                            path={['/programs/:bid?/:pid?', '/search/:searchString/:bid?/:pid?']}
                        >
                            <ScienceFields />
                        </Route>

                        <Route
                            render={routeProps => {
                                const showSchoolFilter = routeProps.location.search.includes(
                                    'schoolFilter=true'
                                );
                                return showSchoolFilter && <SchoolFilter />;
                            }}
                        />

                        <Route path={['/programs/:bid/:pid', '/search/:searchString/:bid/:pid']}>
                            <ResultBox />
                        </Route>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({ dataState: state.dataState });

const Layout = connect(
    mapStateToProps,
    { ...dataActions, ...uiActions }
)(LayoutComponent);

const LayoutWithRouter = withRouter(Layout);

const App = () => {
    return (
        <Router>
            <Provider store={store}>
                <LayoutWithRouter />
            </Provider>
        </Router>
    );
};

export default App;
