import React from 'react';
import styles from './resultScale.module.scss';

export const ResultScale = ({ gpa, minGradeValue, success }) => {
    const leftPos = gpa > 10 ? 100 : gpa * 10;
    return (
        <div className={styles.wrapper}>
            <div className={styles.scaleWrapper}>
                <div className={styles.scale}>
                    <div
                        className={styles.failZone}
                        style={{ width: `${minGradeValue * 10}%` }}
                    />
                    <div
                        className={`${styles.gpaBaloon} ${success ? styles.succeeded : styles.failed}`}
                        style={{ left: `${leftPos}%` }}
                    >
                        <span className={styles.gpaValue}>{gpa}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};
