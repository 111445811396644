export const gradeTypes = { EXAM: 'exam', ANNUAL: 'annual', TEST: 'test' };

export const factorTable = {
    A: { 4: 3.6, 5: 3.9, 6: 4.2, 7: 4.5, 8: 4.8, 9: 5.1, 10: 5.4 },
    B: { 4: 1.8, 5: 2.0, 6: 2.1, 7: 2.3, 8: 2.4, 9: 2.6, 10: 2.7 },
};

export const subjectIdByName = {
    lithuanianLng: 1,
    motherLng: 2,
    foreignLng: 3,
    history: 5,
    mathematics: 6,
    biology: 8,
    chemistry: 9,
    physics: 10,
    geography: 11,
    artEntrance: 13,
    arts: 15,
    sport: 18,
    sportAchievement: 19,
    motivationTest: 20,
    technology: 21,
    musicology: 22,
    music: 23,
    englishLang: 24,
    russianLang: 27,
    SC_SPORT: 28,
    SC_MATH_CHEM: 29,
};

export const branchIdByName = {
    music: 'P03',
};

export const scienceFieldIdByName = {
    artScienceField: 15,
};

export const examGradeSpecialCaseSubjecIds = [subjectIdByName.musicology, subjectIdByName.SC_SPORT];

export const minimalExamValue = 1.6;
export const minimalExamRawValue = minimalExamValue * 10;
export const minimalUniversityBaseSubjectsExamValue = 40;
export const minimalCollegeBaseSubjectsExamValue = 25;

export const minimalUniversityBaseSubjectsAnnualAverage = 7;
export const minimalCollegeBaseSubjectsAnnualAverage = 6;

export const codes = {
    sport: ['R01', 'R02'],
    pedagogy: ['M01'],
    artPedagogy: [
        '6121MX062', // Mokomojo dalyko pedagogika
        '6531MX005', // Šokio pedagogika
        '6121MX041', // Muzikos pedagogika
        // // '6121MX051',
        // // '6121MX002',
        // '6121MX041', // Muzikos pedagogika, Šiaulių universitetas
        // '202005213', // Muzikos pedagogika, VDU
        // '21', // Mokomojo dalyko pedagogika, VDU
        // '20200526', // Sokio pedagogika, VK
        // // '6531MX005',
        // // '6121MX035'
    ],
    subjectPedagogy: [
        '6531MX013',
        '6531MX014',
        '6121MX033',
        '6121MX050',
        '6121MX052',
        '6121MX053',
        '6121MX054',
        '6531MX001',
        '6121MX001',
        '6531MX010',
        '6121MX037',
        '6531MX007',
        '6531MX008',
        '6121MX044',
        '6121MX045',
        '6121MX039',
        '6121MX042',
        '6531MX012',
        '6531MX002',
        '6531MX003',
        '6531MX006',
        '6121MX036',
        '20',
        '23',
        '21',
        '27',
        '28',
        '29',
        '26',
        '26A',
        '31',
    ],
    humanitariant: [
        'N01',
        'N02',
        'N03',
        'N04',
        'N05',
        'N06',
        'N07',
        'N08',
        'N09',
        'N10',
        'N11',
        'N12',
        'N13',
        'N14',
        'N15',
    ],
    subjectPedagogyHumanitarian: ['21', '23', '6121MX036'],
};

export const requires = {
    // Kataliku technologijos programa
    priestRecomendation: {
        key: 'programKey',
        list: ['6121NX006', '6121NX046'],
        textId: 29,
    },
    // VU žurnalistika
    // creativeTest: {
    //     key: 'programKey',
    //     list: [
    //         '6121JX025'
    //     ],
    //     textId: 11,
    // },
    // Dantų technologija (2 kolegijos)
    manualSkillsTest: {
        key: 'programKey',
        list: ['6531GX026', '6531GX043'],
        textId: 12,
    },
    // Teisė ir policijos veikla
    ministryOfTheInteriorPermission: {
        key: 'programKey',
        list: [
            '6121KX010', // Teisė ir valstybės sienos apsauga
            '6121KX007', // Teisė ir penitencinė veikla
            '6121KX008', // Teisė ir policijos veikla
            '202005262', // Policijos veikla
        ],
        textId: 14,
    },
    // Karo akadimija
    toSuitWarAcademyRequirements: {
        key: 'schoolId',
        list: [42],
        textId: 15,
    },
    // Orlaivių pilotavimo (6011EX001) ir Skrydžių valdymo (6011EX002)
    toSuitPilotRequirements: {
        key: 'programKey',
        list: ['6011EX001', '6011EX002'],
        textId: 28,
    },
    // Logopedija
    toSuitLogopedRequirements: {
        key: 'programKey',
        list: ['6121MX042'],
        textId: 39,
    },
};
