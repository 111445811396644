const localStorageIsSupported = (() => {
    try {
        const mod = 'lsTest';
        localStorage.setItem(mod, mod);
        localStorage.removeItem(mod);
        return true;
    } catch(e) {
        return false;
    }
})();

const prepareData = data => {
    switch (typeof data) {
        case 'object':
            return JSON.stringify(data);
        default:
            return data;
    }
};

export const saveToLocalStorage = (name, data) => {
    if (localStorageIsSupported) {
        localStorage.setItem(name, prepareData(data));
    }
};

export const getFromLocalStorage = name => {
    if (localStorageIsSupported) {
        const string = localStorage.getItem(name);
        try {
            const obj = JSON.parse(string);
            return obj;
        } catch (e) {
            return string;
        }
    }
};

export const removeFromLocalStorage = name => {
    if (localStorageIsSupported) {
        localStorage.removeItem(name);
    }
};
