import React, { Component } from 'react';
import get from 'lodash.get';

import styles from './draggable.module.scss';

export const Draggable = props => {
    const { disabled, className, children } = props;

    if (disabled) {
        return (
            <div className={`${styles.wrapperDisabled} ${className}`}>
                {children}
            </div>
        );

    } else {
        return (
            <DraggableEnabled {...props} />
        );
    }
};

class DraggableEnabled extends Component {
    constructor(props) {
        super(props);
        this.el = null;

        this.state = {
            dragging: false,
        };
    }

    componentDidMount() {
        this.readCssTransformValue();
    }

    onMouseDown = event => {
        this.readCssTransformValue();
        this.setState({ dragging: true });
        
        this.initalOffset = {
            mouse: {
                x: event.pageX,
                y: event.pageY,
            },
            el: {
                x: this.state.elX,
                y: this.state.elY
            },
        };
        this.trackMouseMove();
    };

    readCssTransformValue = () => {
        const matrix = (getComputedStyle(this.el)).transform;
        const values = matrix.match(/matrix\((.+)\)/);
        if (!values) {
            console.error('transform values are null');
            return;
        }
        const matches = values[1].split(', ').map(parseFloat);
        
        if (matches.length === 6) {
            this.setState({
                elX: matches[4],
                elY: matches[5],
            });
        }
    };

    onMouseUp = () => {
        this.setState({ dragging: false });
        this.stopTrackingMouseMove();
    };

    trackMouseMove = () => document.addEventListener('mousemove', this.onMouseMove);

    stopTrackingMouseMove = () => document.removeEventListener('mousemove', this.onMouseMove);

    onMouseMove = e => {
        const offsetX = e.pageX || get(e, 'changedTouches[0].pageX');
        const offsetY = e.pageY || get(e, 'changedTouches[0].pageY');
        const diffX = offsetX - this.initalOffset.mouse.x + this.initalOffset.el.x;
        const diffY = offsetY - this.initalOffset.mouse.y + this.initalOffset.el.y;

        this.setState({
            elX: diffX,
            elY: diffY,
        });
    };

    render() {
        const { children, className } = this.props;
        let { elX, elY } = this.state;
        let style;

        if (typeof elX !== undefined) {
            style = {
                transform: `translate(${elX}px, ${elY}px)`
            };
        }

        return (
            <div
                className={`${styles.wrapper} ${className}`}
                ref={el => this.el = el}
                onMouseDown={this.onMouseDown}
                onMouseUp={this.onMouseUp}
                // onMouseLeave={this.onMouseUp}
                style={style}
            >
                {children}
            </div>
        );
    }
}
