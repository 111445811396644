import React from 'react';
import { Link } from '../Link/Link';
import classnames from 'classnames';

import styles from './programListItem.module.scss';

export const ProgramListItem = ({ program, school, searchString, isActive, lastItem }) => {
    const pid = program.programa_id;
    const bid = program.programa_kryptis_id;
    const href = searchString ?
        `/search/${encodeURIComponent(searchString)}/${bid}/${pid}`
        :
        `/programs/${bid}/${pid}`;
    const wrapperClassNames = classnames(styles.wrapper, {
        [styles.isActive]: isActive,
        [styles.lastItem]: lastItem,
    });

    return (
        <li className={wrapperClassNames}>
            <Link className={styles.link} to={href}>
                <span className={styles.program}>{program.programa_name}</span>
                <span className={styles.school}>
                    {school.mokykla_name}
                </span>
            </Link>
        </li>
    );
};
