
export const setGradeValue = payload => {
    return {
        type: 'SET_GRADE_VALUE',
        payload
    }
};

export const setGradeLevel = payload => {
    return {
        type: 'SET_GRADE_LEVEL',
        payload
    }
};
