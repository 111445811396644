import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash.get';

import { BranchBlock } from '../BranchBlock/BranchBlock';
import { clearSchoolFilter } from '../../actions/dataActions';
import { Link } from '../Link/Link';
import { refreshDesktopAds } from '../../helpers/adsHelper';
import { ACTION, trackEvent } from '../../helpers/trackingHelper';

import styles from './scienceField.module.scss';

const track = {
    showSchoolFilter: () => {
        trackEvent({
            action: ACTION.CLICK,
            label: 'programs:showSchoolFilter'
        });
    },
    clearSchoolFilter: () => {
        trackEvent({
            action: ACTION.CLICK,
            label: 'programs:clearSchoolFilter'
        });
    },
    noPrograms: ({ searchString, selectedSchools }) => {
        trackEvent({
            action: ACTION.MESSAGE,
            label: `programs:noResults:${searchString}:${selectedSchools.join(',')}`
        });
    }
};

class ScienceFieldsComponent extends Component {
    componentDidUpdate(previousProps) {
        const { dataState } = this.props;
        const filteredPrograms = get(dataState, 'filteredPrograms');
        const oldFilteredPrograms = get(previousProps, 'dataState.filteredPrograms');

        if (oldFilteredPrograms.length !== filteredPrograms.length) {
            refreshDesktopAds();
        }
    }

    render() {
        const { dataState } = this.props;
        const { searchString } = dataState.pathParams || {};
        const scienceFields = get(dataState, 'sritis') || [];
        const allBranches = get(dataState, 'kryptis') || [];
        const filteredPrograms = get(dataState, 'filteredPrograms') || {};
        const selectedSchools = get(dataState, 'selectedSchools') || [];
        const totalSchoolCount = (get(dataState, 'mokykla') || []).length;

        let searchInfoBlock;
        let selectedSchoolsBlock;

        if (searchString) {
            searchInfoBlock = (
                <div className={styles.searchInfoBlock}>
                    Ieškota studijų programa: <strong>{searchString}</strong>
                </div>
            );
        }

        if (selectedSchools.length && selectedSchools.length < totalSchoolCount) {
            selectedSchoolsBlock = (
                <div className={styles.selectedSchoolsBlock}>
                    Rodomos pasirinktų aukštųjų mokyklų studijų programos. &nbsp;
                    <Link set={{ schools: null }} className={styles.clearSchoolFilterBtn}>Rodyti visas</Link>
                </div>
            );
        } else {
            selectedSchoolsBlock = (
                <div className={styles.selectedSchoolsBlock}>
                    Rodomos visų universitetų ir kolegijų programos. Jei norite patikslinti paiešką,&nbsp;
                    <Link set={{ schoolFilter: true }} onClick={track.showSchoolFilter}>pasirinkite mokyklą</Link>.
                </div>
            );
        }

        // show no results
        if (searchString && filteredPrograms.length === 0) {
            track.noPrograms({
                searchString,
                selectedSchools
            });
            return (
                <div>
                    <div className={styles.infoBlock}>
                        {searchInfoBlock}
                        {selectedSchoolsBlock}
                    </div>

                    <div className={`${styles.infoBlock} ${styles.noResultsWrapper}`}>
                        <h3>Nieko nerasta pagal "{searchString}"</h3>
                    </div>
                </div>
            );
        }

        return (
            <div className={styles.resultsWrapper}>
                {(searchInfoBlock || selectedSchoolsBlock) &&
                    <div className={styles.infoBlock}>
                        {searchInfoBlock}
                        {selectedSchoolsBlock}
                    </div>
                }


                {scienceFields && scienceFields.map(scienceField => {
                    const branches = allBranches.filter(item => item.kryptis_sritis_id === scienceField.sritis_id);

                    return (
                        <ScienceFieldBlock
                            key={scienceField.sritis_id}
                            filteredPrograms={filteredPrograms}
                            scienceField={scienceField}
                            branches={branches}
                        />
                    );
                })}
            </div>
        );
    }
}

const mapStateToProps = state => ({ dataState: state.dataState });

export const ScienceFields = withRouter(connect(mapStateToProps, { clearSchoolFilter })(ScienceFieldsComponent));

const ScienceFieldBlock = ({ filteredPrograms, scienceField, branches }) => {
    const programsCount = branches.filter(({ kryptis_id: branchId }) => {
        const branchPrograms = filteredPrograms.filter(({ programa_kryptis_id }) => programa_kryptis_id === branchId);
        return branchPrograms.length;
    }).length;

    // don't show empty scienceFields in search
    if (!programsCount) {
        return null;
    }

    return (
        <div>
            <h3 className={styles.scienceFieldTitle}>{scienceField.sritis_name}</h3>
            {branches.map(branch => (<BranchBlock key={branch.kryptis_id} branch={branch} />))}
        </div>
    );
};
