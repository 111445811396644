import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from '../Link/Link';

import { SearchField } from '../SearchField/SearchField';
import { clearSchoolFilter } from '../../actions/dataActions';

import styles from './home.module.scss';
import { ACTION, trackEvent } from '../../helpers/trackingHelper';

const track = {
    click: name => {
        trackEvent({
            action: ACTION.CLICK,
            label: `btnClick:${name}`,
        });
    },
};

// export @connect(state => ({ dataState: state.dataState }), { clearSchoolFilter })
class HomeComponent extends Component {
    clearSchoolFilter = () => {
        this.props.clearSchoolFilter();
        track.click('home:showPrograms');
    };

    componentDidMount() {
        document.documentElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    }

    render() {
        const { isMobile } = this.props;

        return (
            <div>
                <div className={styles.blockWrapper}>
                    <h2>Rinkitės programą</h2>
                    <div className={styles.btnWrapper}>
                        <Link
                            className={styles.btn}
                            to="/programs"
                            onClick={this.clearSchoolFilter}
                        >
                            Pagal studijų kryptį
                        </Link>
                    </div>
                </div>

                <div className={styles.blockWrapper}>
                    <h2>Rinkitės programą</h2>
                    <div className={styles.btnWrapper}>
                        <Link
                            className={styles.btn}
                            to="/programs"
                            set={{ schoolFilter: true }}
                            onClick={() => track.click('home:showSchoolFilter')}
                        >
                            Pagal aukštąją
                        </Link>
                    </div>
                </div>

                <div className={styles.blockWrapper}>
                    <div className={styles.btnWrapper}>
                        <h2>Ieškoti programos</h2>
                        <SearchField
                            wrapperStyle={styles.searchField}
                            instant={false}
                            showButton={true}
                            autoFocus={!isMobile}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({ dataState: state.dataState });

export const Home = connect(mapStateToProps, { clearSchoolFilter })(HomeComponent);
