import { codes } from '../constants';

const isSport = branchId => {
    return codes.sport.includes(branchId);
};

const isArt = branchId => {
    return /^P\d{2}/.test(branchId);
};

const isPedagogy = branchId => {
    return codes.pedagogy.includes(branchId);
};

const isArtPedagogy = programKey => {
    return codes.artPedagogy.includes(programKey);
};

const isHumanitarian = branchId => {
    return codes.humanitariant.includes(branchId);
};

const isSubjectPedagogyHumanitarian = programKey => {
    return codes.subjectPedagogyHumanitarian.includes(programKey);
};

export const rulesExtraParamsBuilder = ({ realBranchId: branchId, programKey }) => {
    return {
        isSport: isSport(branchId),
        isArt: isArt(branchId),
        isPedagogy: isPedagogy(branchId),
        isArtPedagogy: isArtPedagogy(programKey),
        isHumanitarian: isHumanitarian(branchId),
        isSubjectPedagogyHumanitarian: isSubjectPedagogyHumanitarian(programKey),
    };
};
