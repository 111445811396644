const eventName = 'KBS';
const dataLayerIsReady = () => typeof dataLayer !== 'undefined' && window.dataLayer.push;
// const dataLayerIsReady = () => window.dataLayer = [];
let skipInitialPageView = true;
let lastPageViewUrl = null;

export const ACTION = {
    CLICK: 'click',
    SEARCH: 'search',
    MESSAGE: 'message',
};

export const trackEvent = ({ action, label = '', value }) => {
    if (!dataLayerIsReady()) {
        return;
    }

    const obj = {
        event: eventName,
        action,
        label,
    };

    if (typeof value !== 'undefined') {
        obj.value = parseInt(value);
    }

    // console.log(obj);
    window.dataLayer.push(obj);

    console.debug('trackEvent', obj);
};

const trackGemius = () => {
    if (typeof gemius_hit === 'function' && typeof pp_gemius_identifier !== 'undefined') {
        window.gemius_hit(window.pp_gemius_identifier.replace('USED_', ''));
    } else {
        console.log('gemius not initialized');
    }
};

export const trackPageView = ({ url, dimensions = {} }) => {
    if (skipInitialPageView) {
        skipInitialPageView = false;
        return;
    }

    if (!dataLayerIsReady()) {
        return;
    }

    if (lastPageViewUrl === url) {
        // prevent double tracking of the same page
        // TODO: refactor trackPageView calls (move out of render)
        return;
    } else {
        lastPageViewUrl = url;
    }

    // no need for trailing slash /kbs/
    if (url === '/') {
        url = '';
    }

    const defaultDimensions = {
        error: '---',
        value: '---',
        adBlock: typeof window.adsBlocked !== 'undefined' ? (window.adsBlocked ? 'y' : 'n') : '---',
    };

    window.dataLayer.push(Object.assign({}, defaultDimensions, dimensions));

    window.dataLayer.push({
        event: 'vpv',
        url: `/kbs${url}`
    });
    console.debug('trackPageView', {
        event: 'vpv',
        url: `/kbs${url}`
    });
    trackGemius();
};
