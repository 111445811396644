import { connect } from 'react-redux';
import React, { Component } from 'react';
import get from 'lodash.get';

import * as uiActions from '../../actions/uiActions';

import styles from './errorMessage.module.scss';
import { ACTION, trackEvent } from '../../helpers/trackingHelper';


// export @connect(state => ({
//     uiState: state.uiState
// }), { ...uiActions })
class ErrorMessageComponent extends Component {

    render() {
        // console.log(uiState)
        const errors = get(this.props, 'uiState.errors') || [];

        if (!errors.length) {
            return null;
        }

        trackEvent({
            action: ACTION.MESSAGE,
            label: `error:${errors[0].code}`
        });

        return (
            <div className={styles.wrapper}>
                {errors[0].message}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    uiState: state.uiState
});

export const ErrorMessage = connect(mapStateToProps, { ...uiActions })(ErrorMessageComponent);
