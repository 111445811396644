import get from 'lodash.get';

import store from '../store';
import { getFromLocalStorage } from './localStorage';
import {
    branchIdByName,
    examGradeSpecialCaseSubjecIds,
    factorTable,
    gradeTypes,
    minimalExamValue,
    subjectIdByName,
} from '../constants';

export const getGradeFromLocalStorage = subjectId => {
    const dataFromLS = getFromLocalStorage('grades');
    // return dataFromLS[subjectId] || {};
    return get(dataFromLS, `[${subjectId}]`) || {};
};

export const round = (val, digits = 2) => {
    const modifier = Math.pow(10, digits);
    return Math.round(val * modifier) / modifier;
};

export const convertAnnualValue = ({ annualGrade = {}, isUniversity }) => {
    const { level, value = 0, isValid } = annualGrade;

    if (!isValid) {
        return 0;
    }

    // universitetam privalomas A lygio metinis pažymys
    if (isUniversity && level === 'B') {
        return 0;
    }
    return get(factorTable, `['${level}'][${value}]`) || 0;
};

export const convertExamValue = ({ subjectId, examGrade = {}, isP0 }) => {
    const { level, value = 0, isValid } = examGrade;
    let final;

    if (!isValid) {
        return 0;
    }

    // SPECIAL CASES
    if (examGradeSpecialCaseSubjecIds.includes(subjectId)) {
        return convertExamValueSpecialCase({ subjectId, examGrade });
    }

    if (!value || value < 16) {
        return 0;
    }

    // Valstybinis egzaminas perskaičiuojama į 10balę sistemą
    if (level === 'V') {
        final = 4 + (6 / 84) * (value - 16);

        // mokyklinis egzaminas perskaičiuojamas pagal factorTable['A']
    } else {
        final = get(factorTable, `['${level}'][${value}]`) || 0;
    }

    // Pirmo dalyko įvertinimas - tik valstybinis egzaminas arba stojamasis egzaminas
    if (isP0) {
        final = final >= minimalExamValue ? final : 0;
    }

    return round(final);
};

const convertExamValueSpecialCase = ({ subjectId, examGrade }) => {
    const { value = 0 } = examGrade;
    const subjectsWithNoChanges = [subjectIdByName.musicology];

    // SPECIAL_CASE
    if (subjectsWithNoChanges.includes(subjectId)) {
        return parseInt(value) || 0;
    }

    if (subjectId === subjectIdByName.SC_SPORT) {
        const sport = getGradeValue({
            subjectId: subjectIdByName.sport,
            gradeType: gradeTypes.ANNUAL,
        });
        const sportAchievement = getGradeValue({
            subjectId: subjectIdByName.sportAchievement,
            gradeType: gradeTypes.EXAM,
        });
        // console.log({
        //     sport,
        //     sportAchievement,
        //     result: sportAchievement * 0.2 + sport * 0.1,
        // });

        return sportAchievement * 0.3 + sport * 0.1;
    }

    throw new Error('Unhandled convertExamValueSpecialCase');
};

export const getBestGrade = ({ grades, subject, subjects, isP0, rules, isUniversity }) => {
    const { dalykas_id: subjectId } = subject;
    const grade = grades[subjectId];
    const settings = get(subject, 'dalykas_settings') || {};

    // SPECIAL_CASE
    // groupMaster is virtual subject used to group foreign languages (as užsienio k.)
    if (settings.groupMaster) {
        return getBestGradeByGroup({
            groupName: settings.groupMaster,
            subjects,
            grades,
            isP0,
            isUniversity,
        });
    }

    // SPECIAL_CASE
    if (settings.specialCase) {
        const math = getBestGrade({
            grades,
            subject: subjects[subjectIdByName.mathematics],
            subjects,
            isP0,
            isUniversity,
        });

        const chem = getBestGrade({
            grades,
            subject: subjects[subjectIdByName.chemistry],
            subjects,
            isP0,
            isUniversity,
        });
        
        const value = (math.value + chem.value) / 2;
        // console.log('specialCase 29', math.value, chem.value, value);
        return {
            value,
            subjectId,
        };
    }

    const annualConverted = convertAnnualValue({
        annualGrade: grade && grade[gradeTypes.ANNUAL],
        subjectId,
        isUniversity,
    });

    const examConverted = convertExamValue({
        examGrade: grade && grade[gradeTypes.EXAM],
        subjectId,
        isP0,
    });

    let useExamValue = false;

    // SPECIAL_CASE
    // P03 (Muzikos kryptis) should use exam value only of Muzikologija
    if (
        get(rules, 'realBranchId') === branchIdByName.music &&
        subject.dalykas_id === subjectIdByName.musicology
    ) {
        console.log('muzikologija', examConverted, grade);
        useExamValue = true;
    }

    // SPECIAL_CASE
    // for first group (isP0) only exam value can be used
    if (isP0) {
        useExamValue = true;
    }

    // SPECIAL_CASE
    // only exam value can be used for Lietuviu kalba
    if (subject.dalykas_id === subjectIdByName.lithuanianLng) {
        useExamValue = true;
    }

    const value = useExamValue ? examConverted : Math.max(annualConverted, examConverted);

    return {
        value,
        subjectId,
    };
};

// Group is a collection of subjects used as virtual subject (f.e. užsienio k.)
const getBestGradeByGroup = ({ subjects, grades, groupName, isP0, isUniversity }) => {
    const subjectsFiltered = Object.values(subjects).filter(
        ({ dalykas_settings }) => dalykas_settings.group === groupName
    );

    const list = subjectsFiltered.map(subject => {
        return getBestGrade({ grades, subject, subjects, isP0, isUniversity });
    });

    const bestValue = Math.max.apply(
        Math,
        list.map(item => item.value)
    );
    const bestSubjectId = list.find(item => item.value === bestValue).subjectId;

    return {
        value: bestValue,
        subjectId: bestSubjectId,
    };
};

export const getGradeValue = ({ subjectId, gradeType }) => {
    const { dataState, gradeState } = store.getState();
    const grades = gradeState.grades;
    const subjects = dataState.dalykas;
    const subject = subjects[subjectId];
    const { dalykas_settings: subjectSettings } = subject;

    const getValue = sId => {
        let isValid = get(grades, `[${sId}][${gradeType}].isValid`);
        let value = get(grades, `[${sId}][${gradeType}].value`);

        if (value === '' || typeof value === 'undefined') {
            value = 0;
        }

        // set isValue = true for empty and never set cells
        if (typeof isValid === 'undefined') {
            isValid = true;
        }

        return isValid ? value : 0;
    };

    if (subjectSettings.groupMaster) {
        const list = Object.values(subjects).filter(
            ({ dalykas_settings }) => dalykas_settings.group === subjectSettings.groupMaster
        );
        const values = list.map(item => getValue(item.dalykas_id));
        return Math.max.apply(Math, values) || 0;
    } else {
        return getValue(subjectId);
    }
};
