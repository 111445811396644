import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { parsePathParams, route } from '../../helpers/routerHelper';
import { ACTION, trackEvent } from '../../helpers/trackingHelper';
import get from 'lodash.get';
import { withRouter } from 'react-router-dom';

import MagnifyIcon from 'mdi-react/MagnifyIcon';

import styles from './searchField.module.scss';


class SearchFieldComponent extends Component {
    state = {
        searchString: '',
        inputValue: '',
    };

    componentDidUpdate() {
        const path = get(this, 'props.dataState.path');
        const { searchString: pathSearchString } = parsePathParams(path);
        const { searchString: currentSearchString } = this.state;

        if (pathSearchString !== currentSearchString) {
            this.setState({
                searchString: pathSearchString,
                inputValue: pathSearchString || '',
            });
        }
    }

    onInputChange = e => {
        console.log('onInputChange')
        const inputValue = get(e, 'target.value');
        if (!inputValue.length) {
            // searchString is empty. Redirect to all programs
            route({ href: `/programs` });
        }

        this.setState({ inputValue });

        if (this.props.instant && inputValue.length >= 3) {
            this.navigate(inputValue);
        }
    };

    onKeypress = e => {
        const { inputValue } = this.state;
        if (e.key === 'Enter') {
            this.navigate(inputValue);
        }
    };

    navigate = (searchString) => {
        if (!searchString) {
            console.log(searchString)
            return;
        }
        trackEvent({
            action: ACTION.SEARCH,
            label: searchString
        });
        route({ path: `/search/${encodeURIComponent(searchString)}` });
    };

    render() {
        const { dataState, wrapperStyle = '', showButton, autoFocus, onBlur = () => {} } = this.props;
        const { inputValue } = this.state;
        const qpSearchString = get(dataState, 'queryParams.searchString');
        const inputClassNames = classnames(styles.input, { [styles.inputWithButton]: showButton });

        return (
            <div className={`${styles.wrapper} ${wrapperStyle}`}>
                <input
                    className={inputClassNames}
                    type="text"
                    placeholder="Pavadinimas"
                    onChange={this.onInputChange}
                    onKeyPress={this.onKeypress}
                    value={inputValue}
                    onBlur={onBlur}
                    autoFocus={autoFocus}
                />
                {showButton &&
                    <MagnifyIcon
                        className={styles.searchButton}
                        onClick={() => this.navigate(inputValue || qpSearchString)}
                    />
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({ dataState: state.dataState });

export const SearchField = withRouter(connect(mapStateToProps)(SearchFieldComponent));
