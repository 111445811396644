import { rulesExtraParamsBuilder } from '../helpers/rulesExtraParamsBuilder';
import { getRulesNoteId } from '../helpers/rulesNoteHelper';
import { parsePathParams } from '../helpers/routerHelper';

const initialState = {
    initialDataFetched: false,
    isMobile: null,
    queryParams: {},
    path: '',
    programs: [],
    mokykla: [],
    filteredPrograms: [],
    rules: {},
    vars: {},
    gradePointData: {},
    selectedSchools: [],
    mandatoryRequirementsErrors: [],
};


const dataReducer = (state = initialState, { type, payload = {} }) => {

    switch (type) {
        case 'FETCH_INITIAL_DATA_FULFILLED':
            return { ...state, ...payload, initialDataFetched: true };

        case 'SET_IS_MOBILE':
            return { ...state, isMobile: payload };

        case 'FILTER_PROGRAMS':
            const { schoolsIds, searchString } = payload;
            // const schoolsIds = schools.split(',')
            //     .filter(item => !!item)
            //     .map(item => +item);
            let filteredPrograms = [];

            if (searchString) {
                // search by branchId
                // if (/^[A-Z]\d+$/i.test(searchString)) {
                //     filteredPrograms = state.programs.filter(program => {
                //         // return program.programa_kryptis_id.toLocaleLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1
                //         return program.programa_kryptis_id.toLocaleLowerCase().includes(searchString.toLocaleLowerCase())
                //     });
                //
                // } else {
                //     filteredPrograms = state.programs.filter(program => {
                //         // return program.programa_name.toLocaleLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1
                //         return program.programa_name.toLocaleLowerCase().includes(searchString.toLocaleLowerCase())
                //     });
                // }
                const searchStringLowCase = searchString.toLocaleLowerCase();
                filteredPrograms = state.programs.filter(program => {
                    return program.programa_name.toLocaleLowerCase().includes(searchStringLowCase) ||
                        program.programa_id === searchString ||
                        program.programa_kryptis_id.toLocaleLowerCase() === searchStringLowCase ||
                        program.programa_code.toLocaleLowerCase().includes(searchStringLowCase);
                });

            } else {
                filteredPrograms = state.programs;
            }

            if (schoolsIds && schoolsIds.length) {
                filteredPrograms = filteredPrograms.filter(program => schoolsIds.includes(program.programa_mokykla_id));
            }

            return {
                ...state,
                filteredPrograms
            };

        case 'SET_QUERY_PARAMS':
            return { ...state, queryParams: payload.queryParams };

        case 'SET_HISTORY':
            return { ...state, history: payload };

        case 'SET_PATH':
            return {
                ...state,
                path: payload.path,
                pathParams: parsePathParams(payload.path),
            };

        case 'FETCH_RULES_FULFILLED':
            payload.extra = rulesExtraParamsBuilder(payload);
            payload.noteId = getRulesNoteId(payload);
            return { ...state, rules: Object.assign({}, state.rules, { [payload.programId]: payload }) };

        case 'SET_GRADE_POINT_DATA':
            return { ...state, gradePointData: payload };

        case 'SET_SCHOOL_SELECTION':
            return { ...state, selectedSchools: payload };

        case 'TOGGLE_SCHOOL_SELECTION':
            console.warn('TOGGLE_SCHOOL_SELECTION deprecated');
            let { selectedSchools } = state;

            payload.forEach(item => {
                const key = +Object.keys(item)[0];
                const value = item[key];
                const keyExist = selectedSchools.includes(key);

                if (!keyExist && value) {
                    selectedSchools.push(key);
                } else if (!value) {
                    selectedSchools = selectedSchools.filter(i => i !== key);
                }
            });
            selectedSchools = selectedSchools.map(item => +item);

            // clear if all schools are selected
            // if (selectedSchools.length === state.mokykla.length) {
            //     selectedSchools = [];
            // }

            return { ...state, selectedSchools };

        case 'CLEAR_SCHOOL_FILTER':
            return { ...state, selectedSchools: [] };

        case 'SET_VARIABLE':
            return { ...state, vars: Object.assign({}, state.vars, payload )};

        case 'SET_MANDATORY_REQUIREMENTS_ERRORS':
            const { mandatoryRequirementsErrors } = payload;
            return { ...state, mandatoryRequirementsErrors }

        case 'RESET_STATE':
            return { ...initialState };

        default:
            return state;
    }
};

export default dataReducer;
