import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import device from 'current-device';
import get from 'lodash.get';
import { isEqual } from '../../helpers/objectCompareHelper';
import { round } from '../../helpers/gradeHelpers';
import { refreshDesktopAds } from '../../helpers/adsHelper';
import { trackPageView } from '../../helpers/trackingHelper';
import { fetchRules } from '../../actions/dataActions';
import { calculator } from '../../calculator';

import WindowCloseIcon from 'mdi-react/WindowCloseIcon';
import { ResultMessage } from './ResultMessage';
import { ResultTable } from './ResultTable';
import { ResultScale } from './ResultScale';
import { ErrorMessage } from '../ErrorMessage/ErrorMessage';
import { Draggable } from '../Draggable/Draggable';
import { Ad } from '../Ad/Ad';

import styles from './resultBox.module.scss';
import { route } from '../../helpers/routerHelper';

const Skeleton = ({ width = '100%', height = '20px', ...rest }) => (
    <div className={styles.skeletonBlock} style={{ width, height, ...rest }} />
);

class ResultBoxComponent extends Component {
    state = {
        programId: null,
        branchId: null,
        gradePoint: null,
    };

    getParams = () => {
        return {
            branchId: get(this.props, 'match.params.bid'),
            programId: get(this.props, 'match.params.pid'),
        };
    };

    shouldComponentUpdate(nextProps, nextState) {
        const { gradeState, dataState, uiState } = this.props;
        const { programId: currentProgramId } = this.getParams();

        const nextProgramId = get(nextProps, 'match.params.pid');

        if (!nextProgramId) {
            return false;
        }

        const currentRules = get(dataState, `rules['${currentProgramId}']`);
        const nextRules = get(nextProps, `dataState.rules['${currentProgramId}']`);

        const currentErrors = get(uiState, 'errors') || [];
        const nextErrors = get(nextProps, 'uiState.errors') || [];

        const currentGradePoint = get(this.state, 'gradePoint');
        const nextGradePoint = get(nextState, 'gradePoint');

        const programIdWillChange = currentProgramId !== nextProgramId;
        const gradesWillChange = !isEqual(gradeState, nextProps.gradeState);
        const rulesWillChange = !isEqual(currentRules, nextRules);
        const gradePointWillChange = !isEqual(currentGradePoint, nextGradePoint);

        const errorsWillChange = !isEqual(
            currentErrors.map(item => item.code),
            nextErrors.map(item => item.code)
        );

        // console.log({ errorsWillChange, currentErrors, nextErrors });
        // console.log({ gradesWillChange, gradeState, nextGradeState: nextProps.gradeState });
        // console.log('shouldUpdate', (programIdWillChange || gradesWillChange || rulesWillChange || errorsWillChange));

        return (
            programIdWillChange ||
            gradesWillChange ||
            rulesWillChange ||
            errorsWillChange ||
            gradePointWillChange
        );
    }

    componentDidUpdate(prevProps, prevState) {
        // console.log('componentDidUpdate', this.props);
        refreshDesktopAds();
        this.doCalculate();
    }

    componentDidMount() {
        // console.log('componentDidMount');
        this.doCalculate();
    }

    doCalculate = () => {
        const { dataState, gradeState, fetchRules } = this.props;
        const { programId } = this.getParams();

        if (!programId) {
            return;
        }

        if (!Object.keys(gradeState.grades).length) {
            console.log('no grades');
            return;
        }

        const rules = get(dataState, `rules['${programId}']`);

        if (!rules) {
            fetchRules({ programId });
        } else {
            const gradePoint = calculator.calculate({ programId });
            if (gradePoint) {
                this.setState({ gradePoint });
            } else {
                console.warn('no gradePoint', this.props);
            }
        }
    };

    onCloseClick = isError => {
        //    to="/" set={{ grades: true, schoolFilter: false }}
        const isMobile = device.mobile();
        const pathSegments = get(this.props, 'dataState.path', '').split('/').filter(Boolean);
        const path = pathSegments.slice(0, pathSegments.length - 1).join('/');
        console.log({
            pathSegments,
            path,
        });
        route({
            path: `/${path}`,
            queryParams: {
                grades: isError && isMobile ? true : null,
            },
        });
    };

    closeIcon = isError => (
        <WindowCloseIcon className={styles.closeIcon} onClick={() => this.onCloseClick(isError)} />
    );

    renderError = () => {
        const { uiState, dataState } = this.props;
        const { path } = dataState;

        const errorCodesString = uiState.errors.map(item => item.code).join(',');

        trackPageView({ url: path, dimensions: { error: errorCodesString } });

        return (
            <div className={`${styles.wrapper} ${styles.error}`}>
                {this.closeIcon(true)}
                <ErrorMessage />
            </div>
        );
    };

    renderResultBox = () => {
        const { dataState } = this.props;
        const { gradePoint } = this.state;
        const isMobile = device.mobile();
        const { dalykas: subjects, mokykla: schools, text, programs, path } = dataState;
        const { programId } = this.getParams();
        const rules = get(dataState, `rules[${programId}]`);

        if (!rules) {
            return this.renderSkeleton();
        }

        const program = programs.find(item => item.programa_id === +programId);
        const note = get(text, `[${rules.noteId}]`);
        const bestCombination = get(gradePoint, 'bestCombination') || {};
        const isError = !bestCombination.value && bestCombination && bestCombination.factors.length;

        // console.log({ gradePoint, bestCombination, subjects, isError });

        if (isError) {
            return null;
        }

        const gpa = round(bestCombination.value);
        const minGradeValue = rules.min_balas;
        const success = gpa >= minGradeValue;
        const viewportHeight = window.innerHeight;
        const innerWrapperMaxHeight = viewportHeight - 400;
        const school = schools.find(item => item.mokykla_id === program.programa_mokykla_id);

        const dimensionValue = `gpa:${gpa}|last:${minGradeValue}|success:${
            success ? 'true' : 'false'
        }|sc:${school.mokykla_short}|st:${school.mokykla_status}`;
        trackPageView({ url: path, dimensions: { value: dimensionValue } });

        return (
            <Draggable className={styles.wrapper} disabled={isMobile}>
                {this.closeIcon(false)}

                <div className={styles.programName}>Programa: {program.programa_name}</div>

                <div className={styles.title}>Tavo konkursinis balas yra {gpa.toFixed(2)}</div>

                <div
                    className={styles.innerWrapper}
                    style={{ maxHeight: `${innerWrapperMaxHeight}px` }}
                >
                    <ResultScale gpa={gpa} minGradeValue={minGradeValue} success={success} />

                    <ResultTable bestCombination={bestCombination} subjects={subjects} />

                    <ResultMessage
                        text={text}
                        gpa={gpa}
                        minGradeValue={minGradeValue}
                        minGradeType={rules.min_balas_type}
                        success={success}
                    />

                    {note && <div className={styles.note}>{note}</div>}
                </div>

                <div className={styles.toc}>{text[38]}</div>
            </Draggable>
        );
    };

    renderSkeleton() {
        const {
            dataState: { text },
        } = this.props;
        const isMobile = device.mobile();

        const detailsProps = {
            height: '25px',
            width: '20%',
        };
        return (
            <div className={styles.wrapper}>
                <div className={styles.programName}>Palaukite...</div>
                <Skeleton height={'30px'} width={'70%'} margin={'20px 0'} />
                <Skeleton height={'8px'} marginBottom={'10px'} />

                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-around',
                        marginBottom: '10px',
                    }}
                >
                    <Skeleton {...detailsProps} />
                    <Skeleton {...detailsProps} />
                    <Skeleton {...detailsProps} />
                    <Skeleton {...detailsProps} />
                </div>

                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        marginBottom: '20px',
                        justifyContent: 'space-around',
                    }}
                >
                    {!isMobile && (
                        <>
                            <Skeleton width={'70px'} height={'70px'} borderRadius={'50%'} />
                            <Skeleton height={'70px'} width={'80%'} />
                        </>
                    )}
                    {isMobile && <Skeleton height={'50px'} width={'90%'} />}
                </div>

                <div className={styles.toc}>{text[38]}</div>
            </div>
        );
    }

    render() {
        const { uiState } = this.props;
        const { gradePoint } = this.state;

        if (uiState?.errors?.length) {
            console.log(uiState.errors);
            return this.renderError();
        }

        if (!gradePoint) {
            return null;
        }

        const isMobile = device.mobile();

        if (isMobile) {
            return (
                <div className={styles.backdrop}>
                    <div className={styles.adHolder}>
                        <Ad />
                    </div>
                    {this.renderResultBox()}
                </div>
            );
        }

        return this.renderResultBox();
    }
}

const mapStateToProps = state => ({
    gradeState: state.gradeState,
    dataState: state.dataState,
    uiState: state.uiState,
});

const ResultBoxConnected = connect(mapStateToProps, { fetchRules })(ResultBoxComponent);

export const ResultBox = withRouter(ResultBoxConnected);
