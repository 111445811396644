import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { route } from '../../helpers/routerHelper';

import { SchoolList } from './SchoolList';
import * as actions from '../../actions/dataActions';
import { ACTION, trackEvent } from '../../helpers/trackingHelper';

import styles from './schoolFilter.module.scss';

class SchoolFilterComponent extends Component {
    state = {
        selectedSchools: [],
    };

    componentDidMount() {
        this.initState();
    }

    onClick = (item, e) => {
        const { isMobile } = this.props.dataState;
        const { selectedSchools } = this.state;
        const schoolId = item.mokykla_id;
        let newSelectedSchools;

        if (e.target.checked) {
            newSelectedSchools = [schoolId, ...selectedSchools];
            this.setState({
                selectedSchools: newSelectedSchools,
            });
        } else {
            newSelectedSchools = selectedSchools.filter(i => i !== schoolId);
            this.setState({
                selectedSchools: newSelectedSchools,
            });
        }

        if (isMobile) {
            this.props.setSchoolSelection(newSelectedSchools);
            setTimeout(() => {
                route({
                    queryParams: {
                        schoolFilter: true,
                    },
                });
            });
        }
    };

    setAll = (schoolType, value) => {
        const { selectedSchools } = this.state;
        const { /* isMobile, */ mokykla: school = [] } = this.props.dataState;

        const schoolGroup = school
            .filter(({ mokykla_status }) => mokykla_status === schoolType)
            .map(item => item.mokykla_id);

        trackEvent({
            action: ACTION.CLICK,
            label: `schoolFiler:${value ? 'selectAll' : 'selectNone'}:type:${schoolType}`,
        });

        if (value) {
            const diff = schoolGroup.filter(item => selectedSchools.includes(item) !== value);
            this.setState({
                selectedSchools: [...selectedSchools, ...diff],
            });
        } else {
            this.setState({
                selectedSchools: selectedSchools.filter(item => !schoolGroup.includes(item)),
            });
        }
    };

    initState = () => {
        const { selectedSchools } = this.props.dataState;
        this.setState({ selectedSchools });
    };

    onSubmit = () => {
        this.props.setSchoolSelection(this.state.selectedSchools);

        setTimeout(() => {
            route({
                path: `/programs`,
                queryParams: {
                    schoolFilter: null,
                },
            });
        });
    };

    onCancel = () => {
        this.initState();
        route({
            queryParams: {
                schoolFilter: null,
            },
        });
    };

    navigate = () => {};

    render() {
        const { isMobile, mokykla: school = [] } = this.props.dataState;
        const { selectedSchools } = this.state;
        const universities = school.filter(({ mokykla_status }) => mokykla_status === 0);
        const colleges = school.filter(({ mokykla_status }) => mokykla_status === 1);

        return (
            <div className={styles.wrapper}>
                <div className={styles.listWrapper}>
                    <SchoolList
                        title="Universitetai"
                        items={universities}
                        selectedSchools={selectedSchools}
                        onClick={this.onClick}
                        selectAll={() => this.setAll(0, true)}
                        selectNone={() => this.setAll(0, false)}
                    />
                    <SchoolList
                        title="Kolegijos"
                        items={colleges}
                        selectedSchools={selectedSchools}
                        onClick={this.onClick}
                        selectAll={() => this.setAll(1, true)}
                        selectNone={() => this.setAll(1, false)}
                    />
                </div>

                <div className={styles.footer}>
                    {!isMobile && (
                        <button className={styles.btnWhite} onClick={this.onCancel}>
                            Atšaukti
                        </button>
                    )}
                    <button className={styles.btn} onClick={this.onSubmit}>
                        OK
                    </button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({ dataState: state.dataState });

export const SchoolFilter = withRouter(
    connect(mapStateToProps, { ...actions })(SchoolFilterComponent)
);
