import React from 'react';
import { round } from '../../helpers/gradeHelpers';

import styles from './resultTable.module.scss';

export const ResultTable = ({ bestCombination, subjects }) => {
    return (
        <div className={styles.wrapper}>
            {bestCombination.subjectIds.map((subjectId, index) => {
                // no group
                if (!subjectId) {
                    return null;
                }

                const { factors, grades, gradesBeforeFactor } = bestCombination;
                const { dalykas_name: subjectName, dalykas_settings: settings } = subjects[subjectId];

                return (
                    <div key={subjectId} className={styles.subjectWrapper}>
                        {/*{subjectId}. */}
                        <div className={styles.subjectName}>
                            {settings.specialCaseTitle || subjectName}
                        </div>

                        <div className={styles.subjectCalcutations}>
                            {settings.specialCase ?
                                round(grades[index])
                                :
                                `${gradesBeforeFactor[index]} * ${factors[index]} = ${round(grades[index])}`
                            }
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
