import React from 'react';
import get from 'lodash.get';
import { getGradeValue, round } from '../helpers/gradeHelpers';
import {
    minimalExamValue,
    minimalExamRawValue,
    minimalUniversityBaseSubjectsExamValue,
    minimalCollegeBaseSubjectsExamValue,
    minimalUniversityBaseSubjectsAnnualAverage,
    minimalCollegeBaseSubjectsAnnualAverage,
    subjectIdByName,
    gradeTypes,
} from '../constants';
import { sortArrayOfObjects } from '../helpers/sortObjectsHelper';

const styles = {
    errorDetails: {
        paddingTop: '10px',
    },
};

export class GeneralRequirement {
    constructor({ text, gradeState, isUniversity, subjects, rules }) {
        // extra data is generated in rulesExtraParamsBuilder
        const {
            isArt,
            isPedagogy,
            isArtPedagogy,
            isHumanitarian,
            isSport,
            isSubjectPedagogyHumanitarian,
        } = rules.extra;

        Object.assign(this, {
            isArt,
            isPedagogy,
            isArtPedagogy,
            isHumanitarian,
            isSport,
            isSubjectPedagogyHumanitarian,
        });

        this.text = text;
        this.grades = gradeState.grades;
        this.subjects = subjects;
        this.rules = rules;
        this.isUniversity = isUniversity;
        this.minimalBaseSubjectsExamValue = this.isUniversity
            ? minimalUniversityBaseSubjectsExamValue
            : minimalCollegeBaseSubjectsExamValue;
        this.minimalBaseSubjectsAnnualAverage = this.isUniversity
            ? minimalUniversityBaseSubjectsAnnualAverage
            : minimalCollegeBaseSubjectsAnnualAverage;
    }

    test() {
        // console.log(this.subjects)
        const errors = [];

        errors.push(
            this.checkLtMinimal(), // 2021 lieka
            this.checkMin3NationalExams(),
            this.check3NationalExamsAverage(),
            // this.checkBaseSubjectsExamAverage(), // Base subject exam average removed in 2020
            // this.checkBaseSubjectsAnnualAverage(), // 2024 requirement removed
            // this.checkB1(), // 2021 NEREIKIA
            this.checkMathMinimal(), // 2021 lieka
            this.checkPedagogicaMotivationTest(), // 2021 lieka
            this.checkEntranceExamForArt() // 2021 lieka
            // sporto reikalavimas?
        );

        return errors.filter(Boolean);
    }

    testMandatory() {
        const errors = [];

        errors.push(
            this.checkLtMinimal()
            // this.checkB1()
            // this.checkBaseSubjectsExamAverage(),
            // this.checkBaseSubjectsAnnualAverage(),

            // this.checkMathMinimal(),
            // this.checkPedagogicaMotivationTest(),
            // this.checkEntranceExamForArt(),
        );

        return errors.filter(Boolean);
    }

    checkMin3NationalExams() {
        const result = Object.entries(this.grades).filter(([index, grade]) => {
            const { exam = {} } = grade;

            const excludeList = [subjectIdByName.artEntrance];
            if (excludeList.includes(+index)) {
                return false;
            }

            return exam.value && exam.isValid && exam.level === 'V';
        });

        // for Art we do NOT need mathematics so minExamsCount is 2
        const minExamsCount = this.isArt || this.isArtPedagogy ? 2 : 3;

        if (result.length < minExamsCount) {
            return this.constructError({
                message: this.text[6],
                code: 'min3NationalExams',
            });
        }
    }

    check3NationalExamsAverage() {
        const result = Object.entries(this.grades).filter(([index, grade]) => {
            const { exam = {} } = grade;

            const excludeList = [subjectIdByName.artEntrance];
            if (excludeList.includes(+index)) {
                return false;
            }

            return exam.value && exam.isValid && exam.level === 'V';
        }).map(item => item[1].exam.value);

        const top3 = result.sort((a, b) => b - a).slice(0, 3);
        const sum  = top3.reduce((acc, val) => acc + val, 0);
        const avg = sum / top3.length;

        if (this.isUniversity && avg < 36) {
            return this.constructError({
                message: this.text[40],
                code: '3NationalExamsAverage',
            });
        } else if (!this.isUniversity && avg < 16) {
            return this.constructError({
                message: this.text[41],
                code: '3NationalExamsAverage',
            });
        }
    }

    checkB1() {
        const result = Object.values(this.subjects)
            .filter(({ dalykas_settings }) => dalykas_settings.b1 === true)
            .find(({ dalykas_id: sId }) => {
                const gradeValue = getGradeValue({ subjectId: sId, gradeType: gradeTypes.EXAM });
                return this.getSubjectExamLevel(sId) === 'V' && gradeValue >= minimalExamValue;
            });

        if (!result) {
            return this.constructError({
                message: this.text[5],
                code: 'b1',
            });
        }
    }

    checkLtMinimal() {
        const ltExamValue = getGradeValue({
            subjectId: subjectIdByName.lithuanianLng,
            gradeType: gradeTypes.EXAM,
        });
        const ltExamLevel = this.getSubjectExamLevel(subjectIdByName.lithuanianLng);

        if (ltExamLevel !== 'V' || round(ltExamValue, 0) < minimalExamValue) {
            return this.constructError({
                message: this.text[4],
                // message: `LT subject minimal not met: ${round(ltExamValue, 0)} < ${minimalExamValue}`,
                code: 'ltMinimal',
            });
        }
    }

    checkMathMinimal() {
        const mathExamValue = getGradeValue({
            subjectId: subjectIdByName.mathematics,
            gradeType: gradeTypes.EXAM,
        });
        const mathExamLevel = this.getSubjectExamLevel(subjectIdByName.mathematics);

        if (this.isArt || this.isArtPedagogy) {
            // + Visoms Menų studijų krypčių (P) programoms matematikos valstybinio egzamino įvertinimas nebūtinas.
            // DO NOTHING
        } else {
            // kitiem reikia ne mažiau 16
            if (mathExamLevel !== 'V' || mathExamValue < 16) {
                return this.constructError({
                    message: this.text[31],
                    code: 'mathMinimal',
                });
            }
        }
    }

    checkPedagogicaMotivationTest() {
        // 2018: check MOTYVACIJOS TESTAS reikalingas visai M01 krypčiai
        // Norint stoti į valstybės finansuojamas pedagoginių programų vietas, reikia turėti motyvacijos testo įvertinimą!
        const motivationTestValue = getGradeValue({
            subjectId: subjectIdByName.motivationTest,
            gradeType: gradeTypes.TEST,
        });

        if (this.isPedagogy && motivationTestValue < 1) {
            return this.constructError({ message: this.text[7] });
        }
    }

    checkBaseSubjectsExamAverage() {
        const baseSubjects = [subjectIdByName.lithuanianLng, subjectIdByName.foreignLng];

        if (!this.isArt && !this.isArtPedagogy) {
            baseSubjects.push(subjectIdByName.mathematics);
        }

        const list = baseSubjects.map(subjectId => {
            return {
                value: getGradeValue({ subjectId, gradeType: gradeTypes.EXAM }),
                subjectId,
            };
        });

        const sum = list.reduce((a, b) => a + b.value, 0);
        const avg = round(sum / list.length, 0);

        // console.log({ list, sum, avg, len: list.length });

        let errorTextId;
        if (this.isArt || this.isArtPedagogy) {
            errorTextId = this.isUniversity ? 34 : 35;
        } else {
            errorTextId = this.isUniversity ? 32 : 33;
        }

        if (avg < this.minimalBaseSubjectsExamValue) {
            return this.constructError({
                // message: this.text[xx]
                message: (
                    <div style={{ 'text-align': 'left' }}>
                        {/*{`checkBaseSubjectsExamAverage (${this.minimalBaseSubjectsExamValue}) not met. Your avg ${avg}`}*/}
                        <div dangerouslySetInnerHTML={{ __html: this.text[errorTextId] }} />
                        Jūsų vidurkis: {avg}.
                        <br />
                        <div style={styles.errorDetails}>
                            <span>Jūsų valstybiniai egzaminai: </span>

                            {list
                                .map(({ subjectId, value }) => {
                                    const subjectName = get(
                                        this.subjects,
                                        `[${subjectId}].dalykas_name`
                                    );
                                    return (
                                        <span>
                                            {subjectName}: {value}
                                        </span>
                                    );
                                })
                                .reduce((prev, curr) => [prev, ', ', curr])}
                        </div>
                    </div>
                ),
                compareString: list
                    .map(({ subjectId, value }) => `${subjectId}-${value}`)
                    .join(','),
                code: 'baseSubjectsExamAverage',
            });
        }
    }

    checkBaseSubjectsAnnualAverage() {
        const baseSubjects = [
            [subjectIdByName.lithuanianLng],
            [subjectIdByName.motherLng],
            [subjectIdByName.foreignLng],
            [subjectIdByName.mathematics],
            [subjectIdByName.history, subjectIdByName.geography],
            [subjectIdByName.biology, subjectIdByName.physics, subjectIdByName.chemistry],
            [subjectIdByName.sport],
            [subjectIdByName.musicology, subjectIdByName.technology, subjectIdByName.arts],
        ];
        const baseSubjectsBestSubjects = baseSubjects.map(list => {
            const baseGroupValues = list.map(subjectId => {
                return getGradeValue({ subjectId, gradeType: gradeTypes.ANNUAL });
            });
            const bestGroupValue = Math.max.apply(Math, baseGroupValues);
            const bestSubjectIndex = baseGroupValues.indexOf(bestGroupValue);

            return {
                subjectId: list[bestSubjectIndex],
                value: bestGroupValue,
            };
        });
        const bestFive = sortArrayOfObjects(baseSubjectsBestSubjects, 'value')
            .reverse()
            .slice(0, 5);
        const sumOfBestFive = bestFive.reduce((a, b) => a + b.value, 0);
        const avg = round(sumOfBestFive / 5, 0);
        // console.log({ baseSubjectsBestSubjects, bestFive, sumOfBestFive, avg, min: this.minimalBaseSubjectsAnnualAverage })

        const errorTextId = this.isUniversity ? 36 : 37;

        if (avg === 0) {
            return this.constructError({
                message: (
                    <div>
                        <div dangerouslySetInnerHTML={{ __html: this.text[errorTextId] }} />
                        Jūsų vidurkis: {avg}.
                        <br />
                        <div style={styles.errorDetails}>
                            <span>Įveskite metinius pažymius!</span>
                        </div>
                    </div>
                ),
                compareString: '0',
                code: 'baseSubjectsAnnualAverage',
            });
        }

        if (avg < this.minimalBaseSubjectsAnnualAverage) {
            return this.constructError({
                message: (
                    <div>
                        <div dangerouslySetInnerHTML={{ __html: this.text[errorTextId] }} />
                        Jūsų vidurkis: {avg}.
                        <br />
                        <div style={styles.errorDetails}>
                            <span>Jūsų 5 geriausi metiniai pažymiai: </span>

                            {bestFive
                                .filter(({ value }) => value > 0)
                                .map(({ subjectId, value }) => {
                                    const subjectName = get(
                                        this.subjects,
                                        `[${subjectId}].dalykas_name`
                                    );
                                    return !value ? null : (
                                        <span key={subjectId}>
                                            {subjectName}: {value}
                                        </span>
                                    );
                                })
                                .reduce((prev, curr) => [prev, ', ', curr])}
                        </div>
                    </div>
                ),
                compareString: bestFive
                    .map(({ subjectId, value }) => `${subjectId}-${value}`)
                    .join(','),
                code: 'baseSubjectsAnnualAverage',
            });
        }
    }

    checkEntranceExamForArt() {
        // console.log('checkEntranceExamForArt', {
        //     isArt: this.isArt,
        //     isArtPedagogy: this.isArtPedagogy,
        // });
        if (!this.isArt && !this.isArtPedagogy) {
            return;
        }

        // console.log('checkEntranceExamForArt');

        const entranceExamValue = getGradeValue({
            subjectId: subjectIdByName.artEntrance,
            gradeType: gradeTypes.EXAM,
        });
        if (entranceExamValue < minimalExamRawValue) {
            return this.constructError({
                message: this.text[10],
                code: 'entranceExamForArt',
            });
        }
    }

    constructError({ message, code, compareString }) {
        console.warn(`requirements not met: [${code}] ${message}`, {
            this: this,
        });
        return {
            compareString,
            message,
            code,
        };
    }

    getSubjectExamLevel(subjectId) {
        return get(this.grades, `[${subjectId}].${gradeTypes.EXAM}.level`);
    }
}
