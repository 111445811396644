import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash.get';
import { isEqual } from '../../helpers/objectCompareHelper';
import { route } from '../../helpers/routerHelper';

import MinusBoxOutlineIcon from 'mdi-react/MinusBoxOutlineIcon';
import PlusBoxOutlineIcon from 'mdi-react/PlusBoxOutlineIcon';


import { ProgramListItem } from '../ProgramListItem/ProgramListItem';

import styles from './branchBlock.module.scss';


// export @connect(state => ({ dataState: state.dataState, uiState: state.uiState }), {  })
class BranchBlockComponent extends Component {
    state = {
        isExpanded: false,
    };

    componentDidMount() {
        this.setExpandedState();
    }

    componentDidUpdate(previousProps) {
        const queryParams = get(this.props, 'dataState.queryParams') || {};

        if (!isEqual(queryParams, previousProps.dataState.queryParams)) {
            this.setExpandedState();
        }
    }

    setExpandedState = () => {
        const queryParams = get(this.props, 'dataState.queryParams') || {};
        const branchId = get(this.props, 'branch.kryptis_id');
        const isSearch = !!queryParams.searchString;
        const isActiveBranch = queryParams.branchId === branchId;
        const filteredSchoolCount = get(this.props, 'dataState.selectedSchools').length;
        const filteredProgramsCount = get(this.props, 'dataState.filteredPrograms').length;

        if (isSearch || isActiveBranch || filteredSchoolCount === 1 || filteredProgramsCount < 100) {
            this.setState({ isExpanded: true });
        }
    };

    onBranchClick = ({ kryptis_id: branchId }) => {
        const { isExpanded } = this.state;
        const queryParams = get(this.props, 'dataState.queryParams') || {};
        const isSearch = !!queryParams.searchString;

        if (!isSearch) {
            route({ href: `/programs/${branchId}/` });
        }

        this.setState({
            isExpanded: !isExpanded
        });
    };

    render() {
        const { branch, dataState } = this.props;
        const { isExpanded } = this.state;
        const filteredPrograms = dataState.filteredPrograms;
        const { kryptis_id: branchId } = branch;
        const schools = get(dataState, 'mokykla') || [];
        const { programId, searchString } = get(dataState, 'pathParams') || {};
        const branchPrograms = filteredPrograms.filter(program => program.programa_kryptis_id === branchId);

        if (!branchPrograms.length) {
            return null;
        }

        return (
            <div className={`${styles.wrapper} ${isExpanded && styles.wrapperIsExpanded}`}>
                <div
                    className={`${styles.title} ${!branchPrograms.length && styles.titleWithNoPrograms} ${isExpanded && styles.isExpanded}`}
                    onClick={() => this.onBranchClick(branch)}
                >
                    {isExpanded ? <MinusBoxOutlineIcon/> : <PlusBoxOutlineIcon />}
                    {branch.kryptis_name}
                </div>

                {isExpanded && <ul className={styles.programsWrapper}>

                    {(branchPrograms).map((program, index) => {
                        const school = schools.find(item => item.mokykla_id === program.programa_mokykla_id)
                        const isActive = +programId === program.programa_id;

                        return (
                            <ProgramListItem
                                key={program.programa_id}
                                isActive={isActive}
                                program={program}
                                school={school}
                                searchString={searchString}
                                lastItem={index === branchPrograms.length - 1}
                            />
                        );
                    })}
                </ul>}
            </div>
        );
    }
}

const mapStateToProps = state => ({ dataState: state.dataState, uiState: state.uiState });

export const BranchBlock = connect(mapStateToProps)(BranchBlockComponent);
