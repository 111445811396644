import React, { Component } from 'react';
import WindowCloseIcon from 'mdi-react/WindowCloseIcon';
import AlertIcon from 'mdi-react/AlertIcon';

import { ACTION, trackEvent } from '../../helpers/trackingHelper';

import styles from './warningMessage.module.scss';

export class WarningMessage extends Component {
    state = {
        isExpanded: true
    };

    toggleBox = () => {
        this.setState({ isExpanded: !this.state.isExpanded });
        trackEvent({
            action: ACTION.CLICK,
            label: `warning:toggleExpand`,
            value: this.state.isExpanded ? 1 : 0
        });
    };

    render() {
        const { errors } = this.props;
        const { isExpanded } = this.state;

        if (!errors.length) {
            return null;
        }

        return (
            <div className={`${styles.wrapper} ${isExpanded ? styles.expanded : ''}`}>

                {isExpanded &&
                    <WindowCloseIcon onClick={this.toggleBox} className={styles.closeIcon} />
                }

                {!isExpanded &&
                    <AlertIcon onClick={this.toggleBox} className={styles.exclamationIcon} />
                }

                {isExpanded && errors[0].message}
            </div>
        );
    }
}
