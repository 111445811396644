import queryString from 'query-string';
import store from '../store';

const replaceCommas = str => {
    return str.replace(/%2C/g, ',');
};

const getSchoolsFilterString = ({ dataState }) => {
    const { selectedSchools } = dataState;

    if (dataState.mokykla && selectedSchools.length === dataState.mokykla.length) {
        return null;
    }
    return selectedSchools.length ? selectedSchools.join(',') : null;
};

export const getHref = ({ path, queryParams = {} }) => {
    const { dataState } = store.getState();
    const qp = { ...queryParams };
    path = path || dataState.path;

    if (typeof qp.schools === 'undefined') {
        qp.schools = getSchoolsFilterString({ dataState });
    }

    Object.keys(qp).forEach(key => {
        if (qp[key] === null) {
            delete qp[key];
        }
    });

    const search = replaceCommas(queryString.stringify(qp));
    return `${path}?${search}`;
};

export const route = ({ path, queryParams, replace, goBack }) => {
    const {
        dataState: { history, path: currentPath },
    } = store.getState();

    path = path || currentPath;

    if (goBack) {
        return history.goBack();
    }

    const href = getHref({ path, queryParams });

    replace ? history.replace(href) : history.push(href);
};

export const parsePathParams = path => {
    const params = {};
    const segments = path.split('/').filter(Boolean);

    switch (segments[0]) {
        case 'programs': {
            params.view = segments[0];
            params.branchId = segments[1];
            params.programId = segments[2];
            break;
        }
        case 'search': {
            params.view = segments[0];
            params.searchString = segments[1];
            params.branchId = segments[2];
            params.programId = segments[3];
            break;
        }
        default:
            break;
    }

    return params;
};
