import React from 'react';
import styles from './resultMessage.module.scss'

const getResult = ({ minGradeValue, minGradeType, gpa, text, success }) => {
    const rtext = { 1: {}, 2: {}, 3: {}, 4: {} };

    rtext[1].main = text[16] + ' {value}. ';
    rtext[1].success = text[17];
    rtext[1].fail = text[18];

    rtext[2].main = text[19] + ' {value}. ';
    rtext[2].success = text[20];
    rtext[2].fail = text[21];

    rtext[3].main = text[22] + ' {value}. ';
    rtext[3].success = text[23];
    rtext[3].fail = text[24];

    rtext[4].main = text[25] + ' {value}. ';
    rtext[4].success = text[26];
    rtext[4].fail = text[27];

    const mainMessage = rtext[minGradeType].main.replace('{value}', minGradeValue);
    const resultMessage = rtext[minGradeType][success ? 'success' : 'fail'];

    return {
        mainMessage,
        resultMessage,
    };
};

const iconSuccess = (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0" y="0" viewBox="0 0 30 30" xmlSpace="preserve" className={`${styles.resultIcon} ${styles.iconSuccess}`}>
                <g id="Check_1"><path d="M14.5,1C6.505,1,0,7.505,0,15.5S6.505,30,14.5,30S29,23.495,29,15.5S22.495,1,14.5,1z M23.126,11.399L12.944,21.581
		c-0.094,0.094-0.221,0.146-0.354,0.146s-0.26-0.053-0.354-0.146l-6.364-6.364c-0.195-0.195-0.195-0.512,0-0.707
		s0.512-0.195,0.707,0l6.01,6.01l9.828-9.829c0.195-0.195,0.512-0.195,0.707,0S23.321,11.204,23.126,11.399z"></path></g>
        </svg>
);

const iconFail = (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0" y="0" viewBox="0 0 30 30" xmlSpace="preserve" className={`${styles.resultIcon} ${styles.iconSuccess}`}>
                <g id="Check_1">
                <path d="M14.5,1C6.505,1,0,7.505,0,15.5S6.505,30,14.5,30S29,23.495,29,15.5S22.495,1,14.5,1z M20.581,20.874
		c0.195,0.195,0.195,0.512,0,0.707c-0.098,0.098-0.226,0.146-0.354,0.146s-0.256-0.049-0.354-0.146L14.5,16.207l-5.374,5.374
		c-0.098,0.098-0.226,0.146-0.354,0.146s-0.256-0.049-0.354-0.146c-0.195-0.195-0.195-0.512,0-0.707l5.374-5.374l-5.374-5.374
		c-0.195-0.195-0.195-0.512,0-0.707s0.512-0.195,0.707,0l5.374,5.374l5.374-5.374c0.195-0.195,0.512-0.195,0.707,0
		s0.195,0.512,0,0.707L15.207,15.5L20.581,20.874z"></path>
                </g>
        </svg>
);

export const ResultMessage = ({ minGradeValue, minGradeType, gpa, text, success }) => {
    if (!minGradeValue) {
        return null;
    }

    const { mainMessage, resultMessage } = getResult({ minGradeValue, minGradeType, gpa, text, success });
    // const iconClass = `${styles.icon} fa-${success ? 'check' : 'times'}-circle`;

    return (
        <div className={`${styles.wrapper} ${success ? styles.success : styles.fail} `}>
            <div className={styles.mainMessage}>
                {success ? iconSuccess : iconFail}
                {mainMessage}
            </div>
            <div className={styles.resultMessage}>{resultMessage}</div>
        </div>
    );
};
