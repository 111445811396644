import { combineReducers } from 'redux';

import gradeReducer from './gradeReducer';
// import filterReducer from './filterReducer';
import dataReducer from './dataReducer';
import uiReducer from './uiReducer';

const rootReducer = combineReducers({
    gradeState: gradeReducer,
    // filterState: filterReducer,
    dataState: dataReducer,
    uiState: uiReducer,
    lastActionType: (state = {}, action) => action.type,
    // test: (state = {}, action) => { console.log(action.type); return '' },
});

export default rootReducer;
