export const sortArrayOfObjects = (arr, key) => {
    if (!Array.isArray(arr) || !key || typeof arr[0][key] === 'undefined') {
        return console.error('Bad input!');
    }

    return arr.sort((a, b) => {
        if (a[key] < b[key])
            return -1;
        if (a[key] > b[key])
            return 1;
        return 0;
    });
};
