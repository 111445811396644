import get from 'lodash.get';
import { saveToLocalStorage } from '../helpers/localStorage';

const lsKey = 'grades';
const initialState = {
    grades: {} // { exam: { level: 'V', value: 100 }, annual: { level: 'A', value: 10 } }
};

const gradeReducer = (state = initialState, { type, payload = {} }) => {
    const { subjectId, gradeType } = payload;
    let gradeCopy = get(state, `grades['${subjectId}']`) || {};
    gradeCopy = JSON.parse(JSON.stringify(gradeCopy));
    let result;

    switch (type) {
        case 'SET_GRADE_VALUE':
            gradeCopy[gradeType] = gradeCopy[gradeType] || {};
            gradeCopy[gradeType].value = payload.value !== '' ? parseInt(payload.value) : '';
            gradeCopy[gradeType].isValid = payload.isValid;
            result = {
                ...state,
                // grades: Object.assign({}, ...state.grades, { [subjectId]: gradeCopy })
                grades: {
                    ...state.grades,
                    [subjectId]: gradeCopy
                },
            };
            if (!payload.skipLocalStorage) {
                saveToLocalStorage(lsKey, result.grades);
            }
            return result;

        case 'SET_GRADE_LEVEL':
            gradeCopy[gradeType] = gradeCopy[gradeType] || {};
            gradeCopy[gradeType].level = payload.level;
            result = {
                ...state,
                // grades: Object.assign({}, ...state.grades,{ [subjectId]: gradeCopy })
                grades: {
                    ...state.grades,
                    [subjectId]: gradeCopy,
                },
            };
            if (!payload.skipLocalStorage) {
                saveToLocalStorage(lsKey, result.grades);
            }
            return result;

        case 'RESET_STATE':
            return { ...initialState };

        default:
            return state;
    }
};

export default gradeReducer;
