import device from 'current-device';

const REFRESH_DELAY = 5 * 1000; // 5 seconds

let refreshTime = Date.now();


const dfpIsReady = () => typeof window.dfpRefresh === 'function';

const _dfpRefresh = () => {
    if (!dfpIsReady()) {
        console.debug('DFP not available');
        return;
    }

    const now = Date.now();

    if (now - refreshTime > REFRESH_DELAY) {
        window.dfpRefresh();
        refreshTime = now;
    }
};

export const refreshDesktopAds = () => {
    if (device.desktop()) {
        _dfpRefresh();
    }
};
