import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/Layout/Layout';

import * as serviceWorker from './serviceWorker';

import 'app-shared/scss/body.scss';

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('kbs-root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
serviceWorker.unregister();
