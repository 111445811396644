const initialState = {
    branchExpanded: {},
    selectedSubjectIds: [],
    errors: [],
    wrapper: null,
};


const uiReducer = (state = initialState, { type, payload = {} }) => {
    const { selectedSubjectIds, errors } = payload;

    switch (type) {
        case 'SET_SELECTED_SUBJECTS':
            return Object.assign({}, state, { selectedSubjectIds });

        case 'SET_ERRORS':
            // return Object.assign({}, state, { errors });
            return { ...state, errors };

        case 'CLEAR_ERRORS':
            // return Object.assign({}, state, { errors: [] });
            return { ...state, errors: [] };

        case 'SET_WRAPPER':
            return { ...state, wrapper: payload };

        default:
            return state;
    }
};

export default uiReducer;
