import { requires } from '../constants';

export const getRulesNoteId = payload => {
    const requirement = Object.values(requires).find(({ key, list, textId }) => {
        if (!payload[key]) {
            console.error(`"${key}" does not exist in payload!`);
            return false;
        }

        return list.includes(payload[key]);
    });

    if (requirement) {
        console.log(requirement);
        return requirement.textId;
    }

    return false;
};
