import React from 'react';
import device from 'current-device';

import styles from './gradeLevelDropdown.module.scss';

const optionHeight = 23;


export const GradeLevelDropdown = ({ options = [], gradeType, selected, onLevelChange }) => {
    const useTouch = !device.desktop();

    if (!options.length || !selected) {
        return null;
    }

    const selectedIndex = options.indexOf(selected);
    const topPosition = (selectedIndex) * optionHeight;

    if (useTouch) {
        return (
            <span className={styles.wrapper}>
                <span className={styles.touchWrapper}>

                    {options.length > 1 && <select
                        onChange={e => onLevelChange(e.target.value)}
                        value={selected}
                    >
                        {options.map(value => {
                            return (<option key={value} value={value}>{value}</option>)
                        })}
                    </select>}

                    {selected}
                </span>
            </span>
        );
    }

    return (
        <span className={styles.wrapper}>
            <span className={styles.innerWrapper}>
                <span className={styles.dropDown} style={{ top: `-${topPosition}px` }}>
                    {options.map(option => {
                        return (
                            <span key={option} className={`${styles.option} ${selected === option ? styles.optionActive : ''}`}
                                  onClick={() => onLevelChange(option)}
                            >
                                {option}
                            </span>
                        );
                    })}
                </span>
            </span>
        </span>
    );
};
