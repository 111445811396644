import queryString from 'query-string';

const host = 'https://kbs.kurstoti.lt/2021/api/kbs-api.php?';

export const fetchInitialData = () => {
    const qpString = queryString.stringify({ action: 'init' });
    return {
        type: 'FETCH_INITIAL_DATA',
        payload: fetch(`${host}${qpString}`).then(result => result.json()),
        // .then(data => ({ qpString, ...data }))
    };
};

export const setIsMobile = state => {
    return {
        type: 'SET_IS_MOBILE',
        payload: state,
    };
};

export const filterPrograms = queryParams => {
    return {
        type: 'FILTER_PROGRAMS',
        payload: queryParams,
    };
};

export const setQueryParams = queryParams => {
    return {
        type: 'SET_QUERY_PARAMS',
        payload: { queryParams },
    };
};

export const setPath = path => {
    return {
        type: 'SET_PATH',
        payload: { path },
    };
};

export const setHistory = history => {
    return {
        type: 'SET_HISTORY',
        payload: history,
    };
};

export const fetchRules = ({ programId }) => {
    const qpString = queryString.stringify({
        action: 'getr',
        pid: programId,
    });

    return {
        type: 'FETCH_RULES',
        payload: fetch(`${host}${qpString}`).then(result => result.json()),
    };
};

export const setGradePointData = payload => {
    return {
        type: 'SET_GRADE_POINT_DATA',
        payload,
    };
};

export const toggleSchoolSelection = payload => {
    return {
        type: 'TOGGLE_SCHOOL_SELECTION',
        payload,
    };
};

export const setSchoolSelection = payload => {
    return {
        type: 'SET_SCHOOL_SELECTION',
        payload,
    };
};

export const clearSchoolFilter = payload => {
    return {
        type: 'CLEAR_SCHOOL_FILTER',
        payload,
    };
};

export const setMandatoryRequirementsErrors = payload => {
    return {
        type: 'SET_MANDATORY_REQUIREMENTS_ERRORS',
        payload,
    };
};
