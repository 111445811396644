import store from '../store';
import get from 'lodash.get';

import { subjectIdByName } from '../constants';
import { getBestGrade } from '../helpers/gradeHelpers';
import * as schoolHelpers from '../helpers/schoolHelpers';
import { GeneralRequirement } from './generalRequirement';
import { setErrors, clearErrors, setSelectedSubjects } from '../actions/uiActions';

export class Calculator {
    calculate({ programId = false }) {
        this.collectData({ programId });
        this.checkRequiredData();

        const generalRequirements = new GeneralRequirement({
            text: this.dataState.text,
            rules: this.rules,
            isUniversity: this.isUniversity,
            gradeState: this.gradeState,
            subjects: this.subjects,
        });
        const generalRequirementsErrors = generalRequirements.test();
        if (generalRequirementsErrors.length) {
            store.dispatch(setErrors({ errors: generalRequirementsErrors }));
            return;
        } else {
            if (this.uiState?.errors?.length) {
                store.dispatch(clearErrors());
            }
        }

        this.combinations = this.generateCombinations(this.groups);

        const {
            bestValue,
            bestCombination,
            bestCombinations,
            allCombinations,
        } = this.calcucalateCombinations({ combinations: this.combinations });

        if (!bestCombinations.length) {
            return console.error('no combinations', this.combinations);
        }
        
        // This prevents from showing result window (not what we want)
        // if (bestValue < 5.4) {
        //     store.dispatch(setErrors({ errors: [{
        //         message: this.dataState.text[this.isUniversity ? 42 : 43],
        //         code: 'minCalculationResult',
        //     }] }));
        //     return;
        // }

        if (this.uiState.selectedSubjectIds.join() !== bestCombinations[0].subjectIds.join()) {
            store.dispatch(
                setSelectedSubjects({ selectedSubjectIds: bestCombinations[0].subjectIds })
            );
        }

        // this.debug({ bestValue, bestCombinations, allCombinations });

        if (bestValue === 0) {
            store.dispatch(
                setErrors({
                    message: `LT subject minimal not met: `,
                    code: 'zeroResult',
                })
            );
        }

        return {
            bestValue,
            bestCombination,
            bestCombinations,
            allCombinations,
        };
    }

    collectData({ programId }) {
        const { dataState, gradeState, uiState } = store.getState();

        if (!programId) {
            return console.log('programId not defined!', dataState);
        }
        this.dataState = dataState;
        this.gradeState = gradeState;
        this.uiState = uiState;
        this.subjects = dataState.dalykas;
        this.schools = dataState.mokykla;
        this.grades = gradeState.grades;
        this.programId = programId;
        this.rules = get(this, `dataState.rules[${this.programId}]`);
        this.schoolId = this.rules.schoolId;
        this.groups = this.rules.groups;
        this.factors = this.rules.factors;
        this.school = this.schools.find(item => item.mokykla_id === this.schoolId);
        this.isUniversity = schoolHelpers.isUniversity(this.school);
    }

    checkRequiredData() {
        const requiredData = [
            this.dataState,
            this.dataState.text,
            this.factors,
            this.gradeState,
            this.groups,
            this.isUniversity,
            this.programId,
            this.rules,
            this.school,
            this.schoolId,
            this.subjects,
        ];
        if (requiredData.some(item => typeof item === 'undefined')) {
            console.error({ requiredData });
            throw new Error('Missing some data');
        }
    }

    calcucalateCombinations({ combinations }) {
        const results = [];
        combinations.forEach(combination => {
            const usedSubjects = [];
            const subjectIds = [];
            const grades = [];
            const factors = [];
            const gradesBeforeFactor = [];

            combination.forEach((subjectId, groupId) => {
                if (usedSubjects.includes(subjectId)) {
                    return 0;
                }
                const isP0 = groupId === 0;
                let {
                    value,
                    factor,
                    valueBeforeFactor,
                    subjectId: bestSubjectId,
                } = this.calculateGrade({ subjectId, isP0, groupId });

                if (isNaN(value)) {
                    value = 0;
                }

                if (value > 0) {
                    usedSubjects.push(subjectId);
                }

                subjectIds.push(bestSubjectId);
                grades.push(value);
                factors.push(factor);
                gradesBeforeFactor.push(valueBeforeFactor);
            });

            const sum = grades.reduce((sum, value) => sum + value);

            results.push({
                // value: sum > 10 ? 10 : sum,
                value: sum,
                subjectIds,
                grades,
                factors,
                gradesBeforeFactor,
            });
        });

        const bestValue = Math.max.apply(
            Math,
            results.map(item => item.value)
        );
        const bestCombinations = results.filter(item => item.value === bestValue);
        const bestCombination = this.getBestCombination(bestCombinations);

        return {
            bestValue,
            bestCombination,
            bestCombinations,
            allCombinations: results,
        };
    }

    getBestCombination(bestCombinations) {
        const compare = (index, a, b) => {
            if (a.grades[index] < b.grades[index]) {
                return -1;
            }
            if (a.grades[index] > b.grades[index]) {
                return 1;
            }
            return 0;
        };

        // use copy as initial value because sort() modifies original array
        return [0, 1, 2, 3].reduce((result, item) => {
            return result.sort(compare.bind(null, item));
        }, bestCombinations.slice())[0];
    }

    calculateGrade({ subjectId, isP0, groupId }) {
        if (!subjectId) {
            return 0;
        }

        const { value: bestGradeResult, subjectId: bestSubjectId } = getBestGrade({
            grades: this.grades,
            subjects: this.subjects,
            subject: this.subjects[subjectId],
            isUniversity: this.isUniversity,
            rules: this.rules,
            isP0,
        });

        const factor = this.getFactor({ subjectId, groupId });
        const afterFactor = bestGradeResult * factor;

        // if (subjectId === 28) {
        //     console.log('28', {
        //         bestGradeResult,
        //         factor,
        //         afterFactor,
        //         grades: this.grades[28]
        //     });
        // }

        return {
            valueBeforeFactor: bestGradeResult,
            value: afterFactor > 10 ? 10 : afterFactor,
            subjectId: bestSubjectId,
            factor,
        };
    }

    generateCombinations(groups) {
        const combinations = [];

        const getPermutation = (array, progress = []) => {
            const segment = array[0];

            segment.forEach(item => {
                if (array.length > 1) {
                    getPermutation(array.slice(1), progress.slice().concat(item));
                } else {
                    combinations.push(progress.concat(item));
                }
            });
        };

        getPermutation(groups);
        return combinations;
    }

    getFactor({ subjectId, groupId }) {
        // Nelaikius muzikologijos egzamino tinka ir muzikos dalyko metinis pazymys
        // tik tuo atverju svertinis koeficijentas 0,01
        if (subjectId === subjectIdByName.music) {
            return 0.01;
        }
        return this.factors[groupId];
    }

    debug({ bestValue, bestCombinations, allCombinations }) {
        console.log({ bestValue, bestCombinations, allCombinations });

        bestCombinations[0].subjectIds.forEach((subjectId, index) => {
            if (subjectId && this.subjects[subjectId]) {
                const subjectName = `${this.subjects[subjectId].dalykas_name} (${subjectId})`;
                const value = bestCombinations[0].grades[index];
                console.log(`${value} - ${subjectName}`);
            }
        });
    }
}

export const calculator = new Calculator();
window.calc = calculator;
