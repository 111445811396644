import React, { Component } from 'react';

import { Home } from '../Home/Home';
import { Link } from '../Link/Link';

import styles from './introMobileBlock.module.scss';

export class IntroMobileBlock extends Component {
    state = {
        introExpanded: null
    };

    componentDidUpdate(previousProps) {
        if (this.props.mandatoryRequirementsErrors.length && this.state.introExpanded === null) {
            this.setState({ introExpanded: true });
        }
    }

    toggleIntroExpandedState = () => {
        this.setState({ introExpanded: !this.state.introExpanded });
    };

    render() {
        const { titleText, introText, mandatoryRequirementsErrors } = this.props;
        const { introExpanded } = this.state;

        if (mandatoryRequirementsErrors.length) {
            return (
                <div className={styles.wrapper}>
                    <h1 className={styles.title}>{titleText}</h1>

                    <div className={`${styles.introText} ${styles.expanded}`}
                         dangerouslySetInnerHTML={{ __html: introText }}
                    />

                    <Link className={styles.startBtn} to="/" set={{ grades: true, schoolFilter: false }}>
                        Pradėti
                    </Link>
                </div>
            );
        }

        return (
            <div className={styles.wrapper}>
                <h1 className={styles.title}>{titleText}</h1>

                <div className={`${styles.introText} ${introExpanded ? styles.expanded : ''}`}
                     dangerouslySetInnerHTML={{ __html: introText }}
                />

                <div className={styles.readMoreLess}
                     onClick={this.toggleIntroExpandedState}
                >
                    {introExpanded ? 'mažiau' : 'daugiau'}
                </div>

                <Home/>
            </div>
        );
    }
}
