import React, { useState } from 'react';
import { SearchField } from '../SearchField/SearchField';
import { Link } from '../Link/Link';
import { ACTION, trackEvent } from '../../helpers/trackingHelper';

import HomeVariantIcon from 'mdi-react/HomeVariantIcon';
import FilterIcon from 'mdi-react/FilterIcon';
import MagnifyIcon from 'mdi-react/MagnifyIcon';

import styles from './topToolbar.module.scss';

const track = {
    homeButton: () => {
        trackEvent({
            action: ACTION.CLICK,
            label: 'homeBtn',
        });
    },
    menuItemClick: name => {
        trackEvent({
            action: ACTION.CLICK,
            label: `menuItem:${name}`,
        });
    },
};

const exclamationIcon = (
    <svg
        className={styles.exclamationIcon}
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="32px"
        height="32px"
        viewBox="0 0 64 64"
        enableBackground="new 0 0 64 64"
        xmlSpace="preserve"
    >
        <g>
            <path
                d="M32,0c-8.547,0-16.583,3.33-22.626,9.375C3.329,15.417,0,23.453,0,32s3.329,16.583,9.374,22.626
		C15.417,60.671,23.453,64,32,64s16.583-3.33,22.627-9.375C60.671,48.583,64,40.547,64,32s-3.329-16.583-9.374-22.626
		C48.583,3.329,40.547,0,32,0z M53.213,53.212C47.546,58.879,40.013,62,32,62c-8.013,0-15.546-3.121-21.212-8.789
		C5.121,47.546,2,40.013,2,32s3.121-15.546,8.788-21.212C16.454,5.121,23.987,2,32,2c8.013,0,15.546,3.122,21.213,8.789
		C58.88,16.454,62,23.987,62,32S58.88,47.546,53.213,53.212z"
            ></path>
            <rect x="31" y="17" width="2" height="22"></rect>
            <rect x="31" y="43" width="2" height="4"></rect>
        </g>
    </svg>
);

export const TopToolbar = ({
    queryParams,
    pathParams,
    currentHref,
    stickyMode,
    isMobile,
    mandatoryRequirementsErrors,
}) => {
    const [showSearchInput, setShowSearchInput] = useState(false);
    const { bid, pid } = queryParams;

    if (isMobile) {
        const programsHrefSegment = ['', 'programs'];
        if (bid) {
            programsHrefSegment.push(bid);
        }
        if (pid) {
            programsHrefSegment.push(pid);
        }
        programsHrefSegment.push('');
        const programsHref = programsHrefSegment.join('/');

        const hasErrors = mandatoryRequirementsErrors.length > 0;

        // TODO: remember branchID and use it next time to open programs list (/programs/X01 instead of /programs/)

        return (
            <div className={`${styles.wrapper}`}>
                <div className={`${styles.topBlock}`}>
                    <Link to="/" onClick={track.homeButton} className={styles.homeIcon}>
                        <HomeVariantIcon />
                    </Link>

                    {!hasErrors && showSearchInput && (
                        <SearchField
                            isMobile={isMobile}
                            showButton
                            autoFocus={true}
                            onBlur={() => setShowSearchInput(false)}
                        />
                    )}
                    {!hasErrors && !showSearchInput && (
                        <>
                            <span className={styles.heading}>Konkursinio balo skaičiuoklė</span>
                            <MagnifyIcon
                                className={styles.magnifyIcon}
                                onClick={() => setShowSearchInput(true)}
                            />
                        </>
                    )}
                </div>

                <div className={styles.submenuBlock}>
                    <Link
                        to="/"
                        set={{ grades: true }}
                        onClick={() => track.menuItemClick('grades')}
                        activeClass={styles.activeSubmenu}
                        currentHref={currentHref}
                    >
                        Mano
                        <br />
                        pažymiai
                    </Link>

                    {mandatoryRequirementsErrors.length === 0 && (
                        <Link
                            set={{ schoolFilter: true }}
                            onClick={() => track.menuItemClick('schoolFilter')}
                            activeClass={styles.activeSubmenu}
                            currentHref={currentHref}
                            removeLastSegment={!!bid}
                            exact
                        >
                            Universitetai
                            <br />
                            ir Kolegijos
                        </Link>
                    )}

                    {mandatoryRequirementsErrors.length === 0 && (
                        <Link
                            to={programsHref}
                            onClick={() => track.menuItemClick('programs')}
                            // set={{ schoolFilter: null, grades: null }}
                            activeClass={styles.activeSubmenu}
                            currentHref={currentHref}
                            isActive={(match, location) => {
                                const { pathname, search } = location;
                                return (
                                    pathname.startsWith('/programs') &&
                                    !search.includes('schoolFilter=true') &&
                                    !search.includes('grades=true')
                                );
                            }}
                        >
                            Studijų
                            <br />
                            Programos
                        </Link>
                    )}

                    {mandatoryRequirementsErrors.length > 0 && (
                        <span className={styles.notificationToInputGrades}>
                            {exclamationIcon}
                            Įveskite privalomus egzaminų ir metinių pažymių įvertinimus!
                        </span>
                    )}
                </div>
            </div>
        );
    }

    return (
        <div className={`${styles.wrapper} ${stickyMode && styles.stickyTopToolbar} `}>
            <div className={styles.topBlock}>
                <Link to="/" onClick={track.homeButton} className={styles.homeIcon}>
                    <HomeVariantIcon />
                </Link>

                <Link
                    set={{ schoolFilter: true }}
                    onClick={() => track.menuItemClick('schoolFilter')}
                    className={styles.schoolFilterButton}
                    removeLastSegment={!!bid}
                >
                    <FilterIcon />
                    &nbsp; Aukštosios mokyklos
                </Link>

                <span className={styles.fillSpace}></span>

                {!isMobile && <SearchField instant showButton />}
            </div>
        </div>
    );
};
