import { createStore, applyMiddleware } from 'redux';
import reduxPromiseMiddleware from 'redux-promise-middleware';
import reduxThunk from 'redux-thunk';
import rootReducer from './reducers/rootReducer';


const store = createStore(
    rootReducer,
    applyMiddleware(reduxPromiseMiddleware, reduxThunk)
);

export default store
