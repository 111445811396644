export const setSelectedSubjects = payload => {
    const { selectedSubjectIds } = payload;
    return {
        type: 'SET_SELECTED_SUBJECTS',
        payload: { selectedSubjectIds },
    };
};

export const setErrors = payload => {
    const { errors } = payload;
    return {
        type: 'SET_ERRORS',
        payload: { errors },
    };
};

export const clearErrors = () => {
    return {
        type: 'CLEAR_ERRORS',
    };
};

export const setWrapper = payload => {
    return {
        type: 'SET_ERRORS',
        payload,
    };
};
