import React, { Component } from 'react';

import styles from './Ad.module.scss';

const pool = (window.adsList || []).filter(ad => ad.device === 'mobile').slice(0,2);
const adZoneId = 'adZone:KBSResultMobile';

export class Ad extends Component {
    constructor(props) {
        super(props);
        const oldAdId = this.getOldAdId();
        const adId = this.getNextAdId(oldAdId);
        this.ad = pool[adId];
    }

    getOldAdId = () => {
        if ('localStorage' in window) {
            const adId = localStorage.getItem(adZoneId);
            return adId === null ? this.getRandomAdId() : parseInt(adId);
        }
        return this.getRandomAdId();
    };

    getNextAdId = oldAdId => {
        let adId = oldAdId + 1;
        adId = pool[adId] ? adId : 0;

        if ('localStorage' in window) {
            localStorage.setItem(adZoneId, adId);
        }

        return adId;
    };

    getRandomAdId = () => {
        return Math.floor(Math.random() * pool.length);
    };

    componentDidMount() {
        if (typeof window.initAd === 'function') {
            console.log('KBS: initAd', this.ad);
            window.initAd(this.ad, true);

        } else {
            console.warn('Failed to initialize ad!', window.initAd);
        }
    }

    componentWillUnmount() {
        try {
            window.destroyAd(this.ad);
        } catch (e) {
            // console.error('Failed to destroy ad', e);
        }
    }

    render() {
        if (!this.ad || !this.ad.divId) {
            return null;
        }

        return this.ad && this.ad.divId && (
            <div className={styles.wrapper}>
                <div id={this.ad.divId}></div>
            </div>
        )
    }
}
