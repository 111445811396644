import React from 'react';
import styles from './schoolList.module.scss';

export const SchoolList = ({ title, items, selectedSchools, onClick, selectAll, selectNone }) => {
    return (
        <div className={styles.wrapper}>
            <h3 className={styles.title}>{title}</h3>
            <div className={styles.selectActionsWrapper}>
                Pažymėti: &nbsp;
                <span className={styles.btn} onClick={selectAll}>visus</span> &nbsp;
                <span className={styles.btn} onClick={selectNone}>nieko</span>
            </div>

            <ul>
                {items.map(item => {
                    if (item.mokykla_is_hidden) {
                        return null;
                    }

                    return (
                        <li className={styles.listItem} key={item.mokykla_id}>
                            <label>
                                <input
                                    type="checkbox"
                                    onChange={e => onClick(item, e)}
                                    checked={selectedSchools.includes(item.mokykla_id)}
                                />
                                {item.mokykla_name}
                            </label>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};
