import React, { useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { getHref } from '../../helpers/routerHelper';

export const Link = props => {
    const {
        to: path,
        currentHref,
        defaultHref,
        set,
        removeLastSegment,
        activeClass,
        onClick,
        scrollTop,
        ...rest
    } = props;

    // let [path, parsed] = prepareHref({
    //     to: toInput,
    //     set,
    //     defaultHref,
    //     removeLastSegment,
    // });

    // const to = stringify({ path, parsed });

    const href = getHref({
        path,
        queryParams: set,
    });

    const _onClick = useCallback(() => {
        if (scrollTop) {
            document.documentElement.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }

        if (typeof onClick === 'function') {
            onClick();
        }
    }, [onClick, scrollTop]);

    const _isActive = useCallback(() => {
        if (typeof props.isActive === 'function') {
            return props.isActive;
        }
        return (match, location) => {
            const { pathname, search } = location;
            return `${pathname}${search}` === href;
        }
    }, [href, props.isActive]);

    // console.log({
    //     path,
    //     parsed,
    //     to,
    //     children: props.children,
    // });

    return <NavLink {...rest} to={href} activeClassName={activeClass} onClick={_onClick} isActive={_isActive()} />;
};
